import React, { useState, useEffect, useRef } from "react";
import {
  getSkills,
  addSkill,
  deleteSkill,
} from "../../../../api/apiCall.js";
import { FaCheckCircle } from "react-icons/fa";

function SkillsExpertise() {
  const user = JSON.parse(localStorage.getItem('user'));
  const storedSkills = JSON.parse(localStorage.getItem('skills'));
  const token = localStorage.getItem('token');

  const [skills, setSkills] = useState(storedSkills)
  const [skillsArray, setSkillsArray] = useState(storedSkills)
  const inputRefs = useRef([]);
  const [loadId, setLoadId] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (isAdded) {
      const timer = setTimeout(() => {
        setVisible(false);
        setIsAdded(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isAdded]);

  const handleClose = () => {
    setVisible(false);
    setIsAdded(false);
  };

  const handleGetSkills = async () => {
    try{
      let data = {
        id: user.id,
        token: token,
      }

      const response = await getSkills(data);
      console.log("Skills: ", response)
      if(response.status === 200){
        setSkills(response.data);
        setSkillsArray(response.data);
      }
    }
    catch(error){
      console.log("Error getting skills: ", error);
    }
    finally{
      setLoadId(null);
      setIsAdding(false);
    }
  };

  const handleAddSkill = async (index) => {
    setIsAdding(true);
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          name: skillsArray[index]
        }
      }

      const response = await addSkill(data);
      if(response.status === 201){
        // setLanguages((prevLanguages) => [...prevLanguages, languagesArray[index]]);
        handleGetSkills();
        setIsAdded(true);
      }
    }
    catch(error){
      console.log("Error adding skill: ", error);
      skillsArray.pop();
    }
  }

  const handleDeleteSkill = async (skillId) => {
    setLoadId(skillId);
    try{
      let data = {
        id: user.id,
        token: token,
        skillId: skillId
      }

      const response = await deleteSkill(data);
      if(response.status === 200){
        handleGetSkills();
      }
    }
    catch(error){
      console.log("Error deleteing language: ", error);
    }
  }

  const handleAddSkillArray = () => {
    setSkillsArray((prevSkillsArray) => [...prevSkillsArray, '']);
  };

  const handleChange = (e, index) => {
    const newKeywords = [...skillsArray];
    newKeywords[index] = e.target.value;
    setSkillsArray(newKeywords);
  };

  useEffect(() => {
    handleGetSkills();
  }, [])

  useEffect(() => {
    if (skillsArray.length > 0) {
      inputRefs.current[skillsArray.length - 1].focus();
    }
  }, [skillsArray]);

  return (
    <>
      <div className="flex flex-col pb-20 max-md:mt-8 max-md:max-w-full">
        {isAdded && (
          <div className="flex items-center self-center mb-2 justify-between gap-2 bg-white border border-gray-300 rounded-lg py-2 px-3 shadow-sm max-w-sm">
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>

            <div className="text-green-600 font-semibold">Expertise Added</div>

            <FaCheckCircle size={20} className="text-green-600" />
          </div>
        )}
        <div className="flex justify-between gap-4 text-base tracking-tight max-md:flex-wrap">
          <div className="flex flex-col text-base tracking-tight max-md:flex-wrap">
            <div className="leading-[110%] text-base font-bold mb-2">
              Add Skills and Expertise
            </div>
            <p className="text-base tracking-tight leading-4 text-neutral-400 max-md:max-w-full">Highlight your strengths! Add your skills and expertise to make your profile stand out!</p>
          </div>
        </div>
        <div className="flex flex-col flex-wrap content-start p-4 mt-6 text-sm leading-4 rounded-2xl border border-solid border-neutral-300 text-neutral-600 max-md:pr-5 max-md:max-w-full">
          <div className="flex gap-2 flex-wrap max-md:pr-5">
            {skillsArray.map((skill, index) => (
                <div key={index} className="relative">
                  {skills.includes(skill) ? (
                    <input
                      type="text"
                      className="justify-center px-4 py-2 rounded-lg bg-neutral-100"
                      value={skill.name}
                      onChange={(e) => handleChange(e, index)}
                      size={(skill.name || '').length || 1}
                      ref={(el) => (inputRefs.current[index] = el)}
                    />
                  ):(
                    <input
                      type="text"
                      className="justify-center px-4 py-2 rounded-lg bg-neutral-100"
                      value={skill.name}
                      onChange={(e) => handleChange(e, index)}
                      size={skill.length || 1}
                      ref={(el) => (inputRefs.current[index] = el)}
                    />
                  )}
                  
                  {skills.includes(skill) ? (
                    <button
                      className="absolute top-[5.5px] right-1 px-2 py-1 text-white bg-red-500 rounded-full"
                      onClick={() => handleDeleteSkill(skill.id)}
                    >
                      {loadId === skill.id ? (
                        <div className="w-[15px] h-[15px] border-2 border-t-transparent border-white rounded-full animate-spin"></div>
                      ):(
                        <span>x</span>
                      )}
                    </button>
                  ):(
                    <button
                      className="absolute top-[5.5px] right-1 px-2 py-1 text-white bg-green-500 rounded-full"
                      onClick={() => handleAddSkill(index)}
                    >
                      {isAdding ? (
                        <div className="w-[15px] h-[15px] border-2 border-t-transparent border-white rounded-full animate-spin"></div>
                      ):(
                        <span>+</span>
                      )}
                    </button>
                  )}
                </div>
              ))}
              <button
                className="justify-center px-4 py-2 rounded-2xl bg-neutral-100 bg-gray-400 text-black"
                onClick={handleAddSkillArray}
              >
                +
              </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SkillsExpertise;
