const HardwareProfessions = [
  "Business Intelligence Analyst",
  "Chip Designer",
  "Cloud Engineer",
  "Compliance Analyst",
  "Cryptographic Engineer",
  "Customer Success Manager for Hardware",
  "Data Center Operations Specialist",
  "EDA Tool Developer",
  "Firmware Developer",
  "Hardware Design Engineer",
  "Hardware Product Manager",
  "Hardware Security Researcher",
  "IoT Developer",
  "Network Architect",
  "PCB Designer",
  "Pre-sales Engineer",
  "QA Engineer",
  "Remote Hardware Tester",
  "Scalability Engineer",
  "Security Consultant",
  "Solutions Architect",
  "Storage Solutions Consultant",
  "Systems Analyst",
  "Technical Support Specialist",
  "Technical Writer",
  "Telecommunications Specialist",
  "Virtualization Engineer",
  "Other",
];

const SoftwareProfessions = [
  "AJAX Developer",
  "Android App Developer",
  "AngularJS Developer",
  "Animators (Games)",
  "Application Programmers",
  "AR/VR Developer",
  "Artificial Intelligence Developer",
  "ASP.NET MVC Specialist",
  "ASP.NET Specialist",
  "Audio Engineer",
  "Augmented Reality Developer",
  "Back End Developer",
  "Backend Developer (AKA Server-Side Developer)",
  "Big Data Developer",
  "Blockchain Developer",
  "Bootstrap Specialist",
  "C# Developers",
  "C++ Developer",
  "C++ Programmer",
  "CAD Engineer",
  "Cloud Solutions Architect",
  "Computer Vision Developer",
  "CRM Developer",
  "CSS Developer",
  "CSS3 Developer",
  "Data Scientist",
  "Database Developer",
  "Desktop Developer",
  "DevOps Developer",
  "DevOps Engineer",
  "Ecommerce Developer",
  "Embedded Developer",
  "Embedded Systems Developer",
  "Enterprise Architect",
  "Front End Developer",
  "Frontend Developer (AKA Client-Side Developer)",
  "Full Stack Developer",
  "Game Designer",
  "Game Developer",
  "Golang Developers",
  "Graphic Designer",
  "Graphics Developer",
  "HTML Developer",
  "HTML5 Developer",
  "Internet-of-Things Developer",
  "iOS Developer",
  "Java Developer",
  "JavaScript Developer",
  "jQuery Developer",
  "Lead Programmer (Games)",
  "Low-Level Developer",
  "Machine Learning Developer",
  "Machine Learning Expert",
  "Magento Developer",
  "Middle-Tier Developer",
  "Mobile App Developer",
  "Mobile Developer",
  "MongoDB Developer",
  "MySQL Developer",
  "MySQL Programmer",
  "Natural Language Processing (NLP) Developer",
  "Network Engineer",
  "Node.js Developer",
  "OpenGL/WebGL Developer",
  "PHP Developer",
  "Python Developers",
  "QA Engineer (Quality Assurance)",
  "React Native Developer",
  "React.js Developers",
  "Responsive Web Designer",
  "Ruby Developer",
  "Ruby on Rails Developer",
  "Rust Developer",
  "Salesforce Developer",
  "Scala Developer",
  "Security Developer",
  "Security Engineer",
  "SEO Expert",
  "Software Developer",
  "Software Development Engineer in Test (SDET)",
  "Software Engineer",
  "SQL Developer",
  "SDE1",
  "SDE2",
  "Systems Analyst",
  "UI/UX Developer or Designer",
  "Virtual Reality Developer",
  "Web Designer",
  "Web Developer",
  "Website Developer",
  "WordPress Developer",
  "Other",
];

export { SoftwareProfessions, HardwareProfessions };
