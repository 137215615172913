import React, { useState, useEffect } from "react";
import Loading from "../../../../component/Loading.js";
import {
  getPortfolios,
  addPortfolio,
  addPortfolioImages,
  updatePortfolio,
  deletePortfolio,
  deletePortfolioImage
} from "../../../../api/apiCall.js";
import { FaCheckCircle } from "react-icons/fa";

function Portfolio() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const [actionResponse, setActionResponse] = useState({ status: null, message: null });
  const [isDeleting, setIsDeleting] = useState({ id: null, deleted: null });
  const [isLoading, setIsLoading] = useState({ adding: false, fetching: false });
  const [updatingId, setUpdatingId] = useState(null);
  const [action, setAction] = useState('Publish');

  const [portfolios, setPortfolios] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isFeatured, setIsFeatured] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedImagesPreview, setUploadedImagesPreview] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (actionResponse) {
      const timer = setTimeout(() => {
        setVisible(false);
        setActionResponse(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [actionResponse]);

  const handleClose = () => {
    setVisible(false);
    setActionResponse(false);
  };

  useEffect(() => {
    const iniialFetch = async () => {
      setIsLoading((prev) => ({ ...prev, fetching: true }));
      await handleGetPortfolios();
      setIsLoading((prev) => ({ ...prev, fetching: false }));
    }

    iniialFetch();
  }, [])

  const handleGetPortfolios = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
      }

      const response = await getPortfolios(data);
      if (response.status === 200) {
        const portfoliosWithState = response.data.map(portfolio => ({
          ...portfolio,
          isFeatured: portfolio.is_featured ? true : false,
        }));
        setPortfolios(portfoliosWithState);
      }
    }
    catch (error) {
      console.log("Error fetching portfolio: ", error);
    }
    finally {

    }
  }

  const handleToggleIsFeatured = async (portfolioId) => {
    try {
      const updatedPortfolios = portfolios.map((portfolio) =>
        portfolio.id === portfolioId
          ? { ...portfolio, isFeatured: !portfolio.isFeatured }
          : portfolio
      );

      setPortfolios(updatedPortfolios);

      let data = {
        id: user.id,
        token: token,
        portfolioId: portfolioId,
        payloads: {
          is_featured: updatedPortfolios.find((p) => p.id === portfolioId).isFeatured ? 1 : 0,
        },
      };

      const response = await updatePortfolio(data);

      if (response.status !== 200) {
        console.log("API update failed. Reverting UI.");
        setPortfolios(portfolios);
      }
    } catch (error) {
      console.log("Error updating isFeatured state: ", error);
      setPortfolios(portfolios); 
    }
  };

  const handleAddPortfolio = async () => {
    setIsLoading((prev) => ({ ...prev, adding: true }));
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          title: title,
          description: description,
          is_featured: isFeatured ? 1 : 0,
        }
      }

      const response = await addPortfolio(data);
      if (response.status === 201) {
        await handleSavePortfolioImages(response.data.data.id);
        await handleGetPortfolios();
        setTitle('');
        setDescription('');
        setIsFeatured(false);
        setUploadedImages([]);
        setUploadedImagesPreview([]);
        setActionResponse({ status: true, message: 'New Portfolio Added' });
      }
    }
    catch (error) {
      console.log("Error adding portfolio: ", error);
    }
    finally {
      setIsLoading((prev) => ({ ...prev, adding: false }));
    }
  }

  const handleSavePortfolioImages = async (portfolioId) => {
    try {
      uploadedImages.forEach(async (item) => {
        let data = {
          id: user.id,
          token: token,
          portfolioId: portfolioId,
          payloads: {
            image: item.file
          }
        }

        const response = await addPortfolioImages(data);
        if (response.status === 201) {

        }
      })
    }
    catch (error) {
      console.log("Error adding work projects: ", error);
    }
    finally {

    }
  }

  const handleDeletePortfolioImage = async (portfolioImageId) => {
    if (typeof portfolioImageId === 'string' && portfolioImageId.includes('e')) {
      setUploadedImagesPreview((prev) =>
        prev.filter((image) => image.id !== portfolioImageId)
      );
      setUploadedImages((prev) =>
        prev.filter((image) => image.id !== portfolioImageId)
      );
      return;
    }

    try {
      let data = {
        id: user.id,
        token: token,
        portfolioId: updatingId,
        portfolioImageId: portfolioImageId
      }

      const response = await deletePortfolioImage(data);
      if (response.status === 200) {
        setUploadedImagesPreview((prev) =>
          prev.filter((image) => image.id !== portfolioImageId)
        );
      }
    }
    catch (error) {
      console.log("Error deleting image: ", error);
    }
    finally {

    }
  }

  const handleFileUpload = (files) => {
    const newImages = [];
    const newPreviews = [];

    Array.from(files).forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Push the new preview into the array
        newPreviews.push({ id: 'e' + uploadedImagesPreview.length, image_path: reader.result }); // Create an object with id and image_path
        // Only set state once, after the loop
        setUploadedImagesPreview((prevPreviews) => [...prevPreviews, ...newPreviews]);
      };
      reader.readAsDataURL(file);
      newImages.push({ id: 'e' + uploadedImagesPreview.length, file: file });
    });

    setUploadedImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    handleFileUpload(e.dataTransfer.files);
  };

  const handleInputChange = (e) => {
    handleFileUpload(e.target.files);
  };

  // const handleChangeIsFeatured = () => {
  //   setIsFeatured((prevIsFeatured) => !prevIsFeatured);
  // }

  const handleChangeIsFeatured = (portfolioId) => {
    handleToggleIsFeatured(portfolioId);
  };

  const handleUpdatePortfolio = (data) => {
    window.scrollTo(0, 0)
    setActionResponse({ status: null, message: null });
    setAction('Update');
    setUpdatingId(data.id);
    setTitle(data.title);
    setDescription(data.description);
    setIsFeatured(data.is_featured);
    setUploadedImagesPreview(data.images);
  }

  const handleCancelUpdatePortfolio = () => {
    setAction('Publish');
    setUpdatingId('');
    setTitle('');
    setDescription('');
    setIsFeatured(false);
    setUploadedImages([]);
    setUploadedImagesPreview([]);
  }

  const handleSaveUpdatePortfolio = async () => {
    setIsLoading((prev) => ({ ...prev, adding: true }));
    try {
      let data = {
        id: user.id,
        token: token,
        portfolioId: updatingId,
        payloads: {
          title: title,
          description: description,
          thumbnail: uploadedImages[0],
          is_featured: isFeatured ? 1 : 0,
        }
      }

      const response = await updatePortfolio(data);
      if (response.status === 200) {
        await handleSavePortfolioImages(updatingId);
        await handleGetPortfolios();
        setAction('Publish');
        setUpdatingId('');
        setTitle('');
        setDescription('');
        setIsFeatured(false);
        setUploadedImages([]);
        setUploadedImagesPreview([]);
        setActionResponse({ status: true, message: 'Portfolio Updated' });
      }
    }
    catch (error) {
      console.log("Error updating portfolio: ", error);
    }
    finally {
      setIsLoading((prev) => ({ ...prev, adding: false }));
    }
  }

  const handleDeletePortfolio = async (portfolioId) => {
    setIsDeleting({ id: portfolioId, deleted: null })
    try {
      let data = {
        id: user.id,
        token: token,
        portfolioId: portfolioId
      }

      const response = await deletePortfolio(data);
      if (response.status === 200) {
        setActionResponse({ status: null, message: null });
        setIsDeleting({ id: portfolioId, deleted: true });
        await handleGetPortfolios();
      }
    }
    catch (error) {
      console.log("Error deleting portfolio: ", error);
      // setIsDeleting({id: workHistoryId, deleted: false});
      // handleGetWorkHistory();
    }
    finally {
      setIsDeleting({ id: null, deleted: null })
    }
  }

  return (
    <>
      {/* ADD PORTFOLIO  */}
      <div className="flex flex-col pb-10 border-b border-solid border-neutral-300 max-md:max-w-full">
        {actionResponse.status && (
          <div className="flex items-center self-center mb-2 justify-between gap-2 bg-white border border-gray-300 rounded-lg py-2 px-3 shadow-sm max-w-sm">
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
            
            <div className="text-green-600 font-semibold">{ actionResponse.message }</div>
            <FaCheckCircle size={20} className="text-green-600" />
          </div>
        )}
        <div className="flex flex-col text-base tracking-tight max-md:flex-wrap">
          <div className="leading-[110%] text-base font-bold mb-2">
            Edit Portfolio
          </div>
          <p className="text-base tracking-tight leading-4 text-neutral-400 max-md:max-w-full">Show off your best work! Upload projects, presentations, or any examples that highlight your skills and achievements. Let your creativity shine!</p>
        </div>
        <div className="flex mt-2 max-[425px]:mt-0 flex-col w-full gap-3 max-md:flex-wrap max-md:max-w-full">
          <div className="flex justify-between gap-3 mt-4 text-base tracking-tight max-md:flex-wrap">
            <div className="leading-[110%] text-neutral-600">
              {action} Item to Portfolio
            </div>
          </div>
          <input
            type="text"
            placeholder="Title"
            value={title}
            className="justify-center p-4 text-base leading-5 whitespace-nowrap rounded-lg border border-solid border-neutral-300 text-black max-md:max-w-full"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="flex mt-2 max-[425px]:mt-0 flex-col w-full gap-3 max-md:flex-wrap max-md:max-w-full">
          <div className="mt-4 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
            Description
          </div>
          <textarea
            placeholder="Description"
            value={description}
            className="justify-center items-start px-4 py-4 text-base leading-5 whitespace-nowrap rounded-lg border border-solid border-neutral-300 text-black max-md:pr-5 max-md:max-w-full"
            onChange={(e) => setDescription(e.target.value)}
          >
          </textarea>
        </div>
        <div className="flex mt-2 max-[425px]:mt-0 flex-col w-full gap-3 max-md:flex-wrap max-md:max-w-full">
          <div className="mt-4 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
            Add Images
          </div>
          <div>
            <label
              htmlFor="file-input"
              className={`relative flex flex-col justify-center items-center py-8 text-lg leading-6 text-center rounded-lg border border-dashed bg-gray-100 border-neutral-400 text-neutral-700 max-md:px-5 max-md:max-w-full cursor-pointer ${isDragging ? 'border-blue-500 bg-blue-100' : ''
                }`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <div>
                Drag or Upload images here
              </div>
              <span className="text-base font-normal text-neutral-400">Add image<br />
                (Image size: 1024px x 687px) PNG, JPG up to 2MB</span>
              <span className="text-base font-bold text-sky-500">Upload</span>
              <input
                id="file-input"
                type="file"
                multiple
                onChange={handleInputChange}
                className="hidden"
              />
            </label>
          </div>
        </div>
        <div className="flex flex-col flex-wrap content-center px-6 py-10 mt-4 rounded-lg border border-dashed bg-gray-100 border-neutral-400 max-md:px-5 max-md:max-w-full">
          <div className="flex flex-wrap gap-5 max-md:flex-wrap max-md:pr-5">
            {uploadedImagesPreview.map((preview, index) => (
              <>
                <img
                  key={index}
                  alt="img"
                  loading="lazy"
                  srcSet={preview.image_path}
                  className="shrink-0 w-20 aspect-square"
                />
                <button type="button" onClick={() => handleDeletePortfolioImage(preview.id)}>X</button>
              </>

            ))}
          </div>
        </div>
        <div className="w-full justify-end flex py-2 mt-8 max-w-full text-base font-semibold text-white w-[309px] max-md:pl-5 max-[425px]:w-full max-[425px]:px-0">
          <button
            className="justify-center whitespace-nowrap px-6 py-3 bg-devRegDarkBlue rounded-full max-md:px-5 w-[230px] h-14 max-md:ml-2 max-[425px]:w-full max-[425px]:ml-0"
            onClick={() => { action === 'Publish' ? handleAddPortfolio() : handleSaveUpdatePortfolio() }}
            disabled={isLoading.adding}
          >
            {isLoading.adding ? (
              <svg
                className="animate-spin h-5 w-5 text-white mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              `${action} Portfolio`
            )}
          </button>
        </div>
        {/* <button 
          className="justify-center self-end px-6 py-3 mt-10 text-base font-semibold text-white bg-blue-900 rounded-2xl max-md:px-5"
          onClick={() => handleAddPortfolio()}
        >
          Publish Portfolio
        </button> */}
      </div>

      {/* PREVIEW PORTFOLIO  */}
      <div className="flex flex-col mt-8 max-md:max-w-full mb-16">
        <div className="text-3xl font-semibold leading-10 text-neutral-800 max-md:max-w-full">
          My Portfolio
        </div>
        <div className="mt-4 max-md:max-w-full">
          <div className="flex flex-wrap justify-between max-md:flex-col max-md:gap-0">
            {!isLoading.fetching ? (
              portfolios.length > 0 ? (
                portfolios.map((portfolio, index) => (
                  <div className="flex flex-col w-[420px] max-md:ml-0 max-md:w-full mb-10 border rounded-lg">
                    <div className="flex flex-col grow max-md:mt-6">
                      <div className="flex w-full overflow-auto gap-2">
                        {portfolio.images?.map((image, index) => (
                          <img
                            alt="img"
                            loading="lazy"
                            srcSet={image.image_path}
                            className="w-full h-8 rounded-t-lg"
                          />
                        ))}
                      </div>
                      <div className="p-4">
                        <div className="mt-2 text-base font-bold leading-6 text-neutral-800">
                          {portfolio.title}
                        </div>
                        <div className="mt-2 text-base leading-6 text-neutral-600">
                          {portfolio.description}
                        </div>
                        <div className="flex justify-between gap-5 mt-2 w-full">
                          <div className="flex gap-2 items-center">
                            <div className="text-base font-medium text-neutral-700">
                              Featured Project
                            </div>
                            <div className="relative">
                            <input
                              type="checkbox"
                              id={`toggle-featured-${portfolio.id}`}
                              checked={portfolio.isFeatured}
                              onChange={() => handleChangeIsFeatured(portfolio.id)}
                              className="sr-only"
                            />
                            <label
                              htmlFor={`toggle-featured-${portfolio.id}`}
                              className="flex items-center cursor-pointer"
                            >
                              <div className={`w-12 h-6 ${portfolio.isFeatured ? 'bg-blue-600' : 'bg-neutral-300'} rounded-full`}>
                                <div
                                  className={`absolute w-6 h-6 bg-white rounded-full transition-transform ${portfolio.isFeatured ? 'translate-x-6' : 'translate-x-0'}`}
                                ></div>
                              </div>
                            </label>
                            </div>
                          </div>

                          {/* actions in this section  */}
                          <div className="self-end flex gap-2 mt-1 text-lg font-medium text-blue-900">
                            <div>
                              <button
                                onClick={() => { updatingId === portfolio.id ? handleCancelUpdatePortfolio() : handleUpdatePortfolio(portfolio) }}
                              >{updatingId === portfolio.id ? 'Cancel' : 'Edit'}</button>
                            </div>
                            <div>|</div>
                            <div>
                              {isDeleting.id === portfolio.id && isDeleting.deleted === null && (
                                <div className="w-[25px] h-[25px] border-2 border-t-transparent border-[#1c3775] rounded-full animate-spin"></div>
                              )}
                              {isDeleting.id === portfolio.id && isDeleting.deleted === true && (
                                <svg width="25" height="25" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.9484 20.8869C9.46389 22.124 10.6735 23.911 11.4639 24.6498C12.8556 26.0072 15.9278 29.0106 17.0824 30.1653C18.2371 31.3199 19.6598 30.6464 20.2268 30.1653L33.1134 17.2787C33.5945 16.7976 34.268 15.4746 33.1134 14.0313C31.9587 12.588 30.4329 13.4299 29.8144 14.0313L18.5257 25.3199L15.0721 21.8663C14.0927 20.8869 12.8041 19.3405 10.9484 20.8869Z" fill="#00A018" />
                                  <circle cx="22" cy="22" r="20" stroke="#00A018" stroke-width="4" />
                                </svg>
                              )}
                              {isDeleting.id !== portfolio.id && (
                                <button className="text-red-500" onClick={() => handleDeletePortfolio(portfolio.id)}>Delete</button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center text-neutral-600 w-full">
                  No Portfolio added yet.
                </div>
              )
            ) : (
              <>
                <div className="w-full flex justify-center min-h-[200px]">
                  <Loading />
                </div>
              </>
            )}
          </div>
        </div>
        {portfolios.length > 6 && (
          <div className="text-center border-t-2 mt-2">See more</div>
        )}
      </div>
    </>
  );
}

export default Portfolio;
