import React from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa6";

function BacktoHomepage() {
  const navigate = useNavigate();
  const redirectToHomepage = () => {
    navigate("/");
  };
  return (
    <>
      <div className="flex gap-2 justify-between max-w-full font-bold text-center text-teal-400">
        <FaChevronLeft className="shrink-0 my-auto w-2.5 aspect-[0.63] fill-teal-400"/>
        <div className="mr-auto cursor-pointer" onClick={redirectToHomepage}>
          Back to Homepage
        </div>
      </div>
    </>
  );
}

export default BacktoHomepage;
