import React from "react";

function DevregEssensial() {
  const cardData = [
    {
      img: "/AboutUsCardImg-1.png",
      title: "Digital Trust",
      desc:
        "In today’s digital age, ensuring identity authenticity is paramount. DevReg® helps developers and clients establish genuine trust, ensuring smooth business interactions and a defense against fraudulent actions.",
    },
    {
      img: "/AboutUsCardImg-2.png",
      title: "Combatting Fraud",
      desc: "Fraudulent activities by pseudo-developers tarnish the reputation of genuine developers globally. Our platform addresses this by providing a shield against these deceptive actions, safeguarding both clients and legitimate developers.",
    },
    {
      img: "/AboutUsCardImg-3.png",
      title: "Boosting Credibility",
      desc: "With DevReg®’s worldwide registration, clients can confidently partner with developers, knowing they’ve been vetted. This transparency benefits authentic developers by enhancing their employability and fostering secure relationships with clients.",
    },
  ];

  const cardData2 = [
    {
      img: "",
      title: "Key Features",
      desc: " Affirm Your Identity with Confidence",
      desc2: `'I AM WHO I SAY I AM.'`,
      desc3: "with DevReg® for Access to Verified Software Developers.",
    },
    {
      img: "/AboutUsCardImg-4.png",
      title: "AML & eIDAS Compliance",
      desc: "We house a database of certified software development professionals compliant with Anti-Money Laundering (AML) and Electronic Identification, Authentication, and Trust Services (eIDAS) protocols.",
    },
    {
      img: "/AboutUsCardImg-5.png",
      title: "Connecting Clients and Developers",
      desc: "Clients can quickly identify and collaborate with verified developers, ensuring a seamless, safe, and efficient project cycle.",
    },
  ];
  return (
    <>
      <div className="max-w-main m-auto bg-[#FAFAFA] rounded-2xl mt-14 px-10 max-md:px-3">
        <div className="font-bold text-[30px] text-devRegDarkBlue mb-6 max-md:px-3 max-md:text-">
          Why is DevReg® Essential?
        </div>
        <div className="flex justify-between max-md:justify-center max-md:px-3 max-md:items-center max-md:flex-col gap-4 max-w-main overflow-hidden">
          {cardData.map((item, index) => (
            <div key={index} className="w-[30%] max-md:mt-5 max-md:w-full">
              <img src={item.img} alt={item.img} className="max-md:w-full" />
              <div className="text-devRegDarkBlue font-semibold my-3 text-[24px]">
                {item.title}
              </div>
              <div className="max-w-[90%] text-black70  text-devRegDarkBlue">{item.desc}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="max-w-main m-auto bg-[#FAFAFA] rounded-2xl mt-10 p-10 max-md:px-3">
        <div className="flex justify-evenly max-md:justify-center max-md:px-3 max-md:items-center max-md:flex-col gap-4 max-w-main overflow-hidden">
          {cardData2.map((item, index) => (
            <div
              key={index}
              className={`w-[30%] max-md:mt-5 max-md:w-full ${
                index === 0 ? "px-10 rounded-xl" : "bg-[#FAFAFA]"
              }`}
            >
              <img src={item.img} alt={item.img} className="max-md:w-1/4" />
              <div
                className={`text-devRegDarkBlue font-semibold my-3 ${
                  index !== 0 ? "text-[24px]" : "text-[30px] mt-20 "
                }`}
              >
                {item.title}
              </div>
              <div className={`max-w-[90%] text-black80 text-sm18 text-devRegDarkBlue`}>
                {item.desc}
              </div>
              <div className="max-w-[90%] text-black80 font-bold mt-5 text-sm18 text-devRegDarkBlue">
                {item.desc2}
              </div>
              <div className="max-w-[90%] text-black80 mt-5 text-sm18 max-md:pb-14 text-devRegDarkBlue">
                {item.desc3}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default DevregEssensial;