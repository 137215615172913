import React, { useState, useEffect } from "react";
import Loading from "../../../../component/Loading.js";
import {
  getHonorsAndAwards,
  addHonorAndAward,
  updateHonorAndAward,
  deleteHonorAndAward,
} from "../../../../api/apiCall.js";
import moment from "moment";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";

function HonorAwards() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const [actionResponse, setActionResponse] = useState({ status: null, message: null });
  const [action, setAction] = useState('Add');
  const [isLoading, setIsLoading] = useState({ adding: false, fetching: false });
  const [isDeleting, setIsDeleting] = useState({ id: null, deleted: null });
  const [updatingId, setUpdatingId] = useState(null);

  const [honorsAndAwards, setHonorsAndAwards] = useState([]);
  const [issuerLogo, setIssuerLogo] = useState(null);
  const [issuerLogoPreview, setIssuerLogoPreview] = useState(null);
  const [awardTitle, setAwardTitle] = useState(null);
  const [nameOfIssuer, setNameOfIssuer] = useState(null);
  const [dateIssued, setDateIssued] = useState(null);
  const [description, setDescription] = useState(null);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (actionResponse) {
      const timer = setTimeout(() => {
        setVisible(false);
        setActionResponse(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [actionResponse]);

  const handleClose = () => {
    setVisible(false);
    setActionResponse(false);
  };

  useEffect(() => {
    const inialFetch = async () => {
      setIsLoading((prev) => ({ ...prev, fetching: true }));
      await handleGetHonorsAndAwards();
      setIsLoading((prev) => ({ ...prev, fetching: false }));
    }

    inialFetch();
  }, [])

  const handleIssuerLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setIssuerLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setIssuerLogo(file);
    }
  };

  const handleGetHonorsAndAwards = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
      }

      const response = await getHonorsAndAwards(data);
      if (response.status === 200) {
        setHonorsAndAwards(response.data);
      }
    }
    catch (error) {
      console.log("Error fetching honors and awards: ", error);
    }
  }

  const handleAddHonorAndAward = async () => {
    setIsLoading((prev) => ({ ...prev, adding: true }));
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          thumbnail: issuerLogo,
          title: awardTitle,
          issued_by: nameOfIssuer,
          issued_date: dateIssued,
          description: description,
        }
      }

      const response = await addHonorAndAward(data);
      if (response.status === 201) {
        await handleGetHonorsAndAwards();
        setIssuerLogo('');
        setIssuerLogoPreview('');
        setAwardTitle('');
        setNameOfIssuer('');
        setDateIssued('');
        setDescription('');
        setIsDeleting({ id: null, deleted: null });
        setActionResponse({ status: true, message: 'Honor / Award Added' });
      }
    }
    catch (error) {
      console.log("Error adding honor/award: ", error);
    }
    finally {
      setIsLoading((prev) => ({ ...prev, adding: false }));
    }
  }

  const handleDeleteHonorAndAward = async (honorAndAwardId) => {
    setIsDeleting({ id: honorAndAwardId, deleted: null });
    try {
      let data = {
        id: user.id,
        token: token,
        honorAndAwardId: honorAndAwardId
      }

      const response = await deleteHonorAndAward(data);
      if (response.status === 200) {
        setActionResponse({ status: null, message: null });
        setIsDeleting({ id: honorAndAwardId, deleted: true });
        handleGetHonorsAndAwards();
      }
    }
    catch (error) {
      console.log("Error deleting education and certificates: ", error);
    }
  }

  const formatDate = (inputDate) => {
    const date = moment(inputDate, 'YYYY-MM-DD');
    return date.format('MMM YYYY');
  }

  const handleUpdateHonorAndAward = (data) => {
    window.scrollTo(0, 0)
    setActionResponse({ status: null, message: null });
    setAction('Update');
    setUpdatingId(data.id);
    setIssuerLogoPreview(data.thumbnail_path);
    setAwardTitle(data.title);
    setNameOfIssuer(data.issued_by);
    setDateIssued(data.issued_date);
    setDescription(data.description);
  }

  const handleCancelUpdateHonorAndAward = () => {
    setAction('Add');
    setUpdatingId('');
    setIssuerLogo('');
    setIssuerLogoPreview('');
    setAwardTitle('');
    setNameOfIssuer('');
    setDateIssued('');
    setDescription('');
  }

  const handleSaveUpdateHonorAndAward = async () => {
    setIsLoading((prev) => ({ ...prev, adding: true }));
    try {
      let data = {
        id: user.id,
        token: token,
        honorAndAwardId: updatingId,
        payloads: {
          thumbnail: issuerLogo,
          title: awardTitle,
          issued_by: nameOfIssuer,
          issued_date: dateIssued,
          description: description,
        }
      }

      const response = await updateHonorAndAward(data);
      if (response.status === 200) {
        await handleGetHonorsAndAwards();
        setAction('Add');
        setUpdatingId('');
        setIssuerLogo('');
        setIssuerLogoPreview('');
        setAwardTitle('');
        setNameOfIssuer('');
        setDateIssued('');
        setDescription('');
        setIsDeleting({ id: null, deleted: null });
        setActionResponse({ status: true, message: 'Honor / Award Updated' });
      }
    }
    catch (error) {
      console.log("Error adding honor/award: ", error);
    }
    finally {
      setIsLoading((prev) => ({ ...prev, adding: false }));
    }
  }

  const [collapsedStates, setCollapsedStates] = useState({});

  const toggleCollapse = (id) => {
    setCollapsedStates((prevStates) => ({
      ...prevStates,
      [id]: !prevStates[id],
    }));
  };

  const isCollapsed = (id) => collapsedStates[id] ?? true;

  return (
    <>
      <div className="flex flex-col  pb-6 border-b border-solid border-neutral-300 max-md:max-w-full">
        {actionResponse.status && (
          <div className="flex items-center self-center mb-2 justify-between gap-2 bg-white border border-gray-300 rounded-lg py-2 px-3 shadow-sm max-w-sm">
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
            
            <div className="text-green-600 font-semibold">{ actionResponse.message }</div>
            <FaCheckCircle size={20} className="text-green-600" />
          </div>
        )}
        <div className="flex justify-between gap-4 text-base tracking-tight max-md:flex-wrap">
          <div className="flex flex-col text-base tracking-tight max-md:flex-wrap">
            <div className="leading-[110%] text-base font-bold mb-2">
              {action} Honor / Award
            </div>
            <p className="text-base tracking-tight leading-4 text-neutral-400 max-md:max-w-full">Showcase your achievements! Add your honors and awards to highlight your notable awards!</p>
          </div>
        </div>
        <div className="mt-6 max-md:max-w-full">
          <div className="flex flex-col gap-5 max-md:gap-0">
            <div className="flex relative flex-col w-full max-md:ml-0 max-md:w-full gap-3">
              <div className="text-base tracking-tight leading-4 font-normal">
                School Logo
              </div>
              <div className="flex flex-col w-[15%] max-[425px]:w-[25%] aspect-square h-auto grow overflow-hidden justify-center text-sm leading-4 text-center rounded-lg border border-solid border-neutral-300 text-stone-300 max-md:mt-0">
                {issuerLogoPreview ? (
                  <img
                    alt="img"
                    loading="lazy"
                    src={issuerLogoPreview}
                    className="self-center w-full"
                  />
                ) : (
                  <>
                    <div className="m-4 flex flex-col grow justify-center">
                      <img
                        alt="img"
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/176be2ea66493ab805bd3d9e5c7cd1f706a0de3766487bde8e82a9332aba88cd?"
                        className="self-center w-11 aspect-[0.95]"
                      />
                      <div>Issuer Logo</div>
                    </div>
                  </>
                )}
                <input
                  type="file"
                  value=""
                  onChange={handleIssuerLogoUpload}
                  className="opacity-0  absolute top-0 left-0 w-full h-full cursor-pointer"
                />
              </div>
            </div>

            <div className="flex flex-col w-full max-md:ml-0 max-md:w-full">
              <div className="flex flex-col w-full justify-center self-stretch my-auto text-base leading-5 placeholder-text-stone-300 max-md:mt-5 max-md:max-w-full">
                <div className="flex mt-2 max-[425px]:mt-0 flex-col w-full gap-3 max-md:flex-wrap max-md:max-w-full">
                  <div className="text-base tracking-tight leading-4 font-normal max-md:max-w-full">
                    Award Title
                  </div>
                  <input
                    type="text"
                    placeholder="Award Title"
                    value={awardTitle}
                    onChange={(e) => setAwardTitle(e.target.value)}
                    className="justify-center p-4 rounded-lg border border-solid border-neutral-300 w-full max-md:max-w-full"
                  />
                </div>
                <div className="flex gap-3 w-full items-center mt-6 max-[425px]:mt-4 max-md:flex-wrap max-md:max-w-full">
                  <div className="flex gap-3 w-full flex-col max-[425px]:w-full">
                    <label className="text-base tracking-tight leading-4 font-normal max-md:max-w-full">Name of Issuer</label>
                    <input
                      type="text"
                      placeholder="Name of Issuer"
                      value={nameOfIssuer}
                      onChange={(e) => setNameOfIssuer(e.target.value)}
                      className="justify-center p-4 text-base rounded-lg border border-solid border-neutral-300 max-md:max-w-full"
                    />
                  </div>
                  <div className="flex gap-3 w-full flex-col max-[425px]:w-full">
                    <label className="text-base tracking-tight leading-4 font-normal max-md:max-w-full">Date Issued</label>
                    <input
                      type="date"
                      value={dateIssued}
                      onChange={(e) => setDateIssued(e.target.value)}
                      placeholder="Date Issued"
                      className="justify-center p-4 text-base rounded-lg border border-solid border-neutral-300 max-md:max-w-full"
                    />
                  </div>
                </div>
                <div className="flex mt-2 max-[425px]:mt-0 flex-col w-full gap-3 max-md:flex-wrap max-md:max-w-full">
                  <div className="mt-4 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
                    Description
                  </div>
                  <textarea
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="justify-center p-4 text-base rounded-lg border border-solid border-neutral-300 max-md:max-w-full"
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full justify-end flex py-2 mt-10 max-w-full text-base font-semibold text-white w-[309px] max-md:pl-5 max-[425px]:w-full max-[425px]:px-0">
          <button
            className="justify-center whitespace-nowrap px-6 py-3 bg-devRegDarkBlue rounded-full max-md:px-5 w-[230px] h-14 max-md:ml-2 max-[425px]:w-full max-[425px]:ml-0"
            onClick={() => { action === 'Add' ? handleAddHonorAndAward() : handleSaveUpdateHonorAndAward() }}
            disabled={isLoading.adding}
          >
            {isLoading.adding ? (
              <svg
                className="animate-spin h-5 w-5 text-white mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              `${action} Honor / Award`
            )}
          </button>
        </div>

        <hr className="my-7" />

        <div className="flex flex-col text-base leading-6 bg-white rounded-xl text-neutral-600 max-md:max-w-full">
          <div className="text-3xl mt-4 mb-9 font-semibold text-neutral-800 max-md:max-w-full">
            Honors and Awards
          </div>

          {!isLoading.fetching ? (
            honorsAndAwards.length > 0 ? (
              honorsAndAwards.map((honorAward, index) => (
                <div key={index}>
                  <div className="flex items-center justify-between max-[425px]:flex-col max-[425px]:items-start">
                    <div className="flex gap-4 max-md:flex-wrap">
                      <img
                        alt="img"
                        loading="lazy"
                        srcSet={honorAward.thumbnail_path}
                        className="shrink-0 aspect-square w-[47px]"
                      />
                      <div className="flex flex-col flex-1 self-start max-md:max-w-full">
                        <div className="font-bold max-md:max-w-full text-devRegDarkBlue">
                          {honorAward.title}
                        </div>
                        <div className="max-md:max-w-full">
                          Issued by {honorAward.issued_by} · {formatDate(honorAward.issued_date)}
                        </div>
                      </div>
                    </div>
                    
                    <div className="flex gap-2 text-lg font-medium text-blue-900 items-center">
                      <div>
                        <button
                          onClick={() => { updatingId === honorAward.id ? handleCancelUpdateHonorAndAward() : handleUpdateHonorAndAward(honorAward) }}
                        >
                          {updatingId === honorAward.id ? 'Cancel' : 'Edit'}
                        </button>
                      </div>
                      <div>|</div>
                      <div>
                        {isDeleting.id === honorAward.id && isDeleting.deleted === null && (
                          <div className="w-[25px] h-[25px] border-2 border-t-transparent border-[#1c3775] rounded-full animate-spin"></div>
                        )}
                        {isDeleting.id === honorAward.id && isDeleting.deleted === true && (
                          <svg width="25" height="25" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.9484 20.8869C9.46389 22.124 10.6735 23.911 11.4639 24.6498C12.8556 26.0072 15.9278 29.0106 17.0824 30.1653C18.2371 31.3199 19.6598 30.6464 20.2268 30.1653L33.1134 17.2787C33.5945 16.7976 34.268 15.4746 33.1134 14.0313C31.9587 12.588 30.4329 13.4299 29.8144 14.0313L18.5257 25.3199L15.0721 21.8663C14.0927 20.8869 12.8041 19.3405 10.9484 20.8869Z" fill="#00A018" />
                            <circle cx="22" cy="22" r="20" stroke="#00A018" stroke-width="4" />
                          </svg>
                        )}
                        {isDeleting.id !== honorAward.id && (
                          <button className="text-red-500" onClick={() => handleDeleteHonorAndAward(honorAward.id)}>Delete</button>
                        )}
                      </div>
                      {/* Collapse/Expand Arrow */}
                      <button onClick={() => toggleCollapse(honorAward.id)} className="ml-2 text-gray-600">
                        {isCollapsed(honorAward.id) ? <FaChevronDown /> : <FaChevronUp />}
                      </button>
                    </div>
                  </div>
                  {!isCollapsed(honorAward.id) && (
                  <div className="mt-4 leading-6 max-md:max-w-full">
                    {honorAward.description}
                  </div>
                  )}
                  <hr className="my-7" />
                </div>
              ))
            ) : (
              <div className="text-center text-neutral-600 w-full">
                No Honors and Awards added yet.
              </div>
            )
          ) : (
            <>
              <div className="w-full flex justify-center min-h-[200px]">
                <Loading />
              </div>
            </>
          )}
        </div>
      </div>

      {honorsAndAwards.length > 5 && (
        <div className="text-center border-t-2 mt-2">See more</div>
      )}

    </>
  );
}

export default HonorAwards;
