import * as React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { FaClock } from "react-icons/fa6";
import { BsCameraVideoFill } from "react-icons/bs";
import "./CalendarGlobal.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../actions";
import {
  setInterviewSchedule,
} from "../../api/apiCall";

function Interview() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [value, onChange] = React.useState(new Date());
  const [selectedTimeType, setSelectedTimeType] = React.useState("AM");
  const [selectedTime, setSelectedTime] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const amTimes = [
    "09:00",
    "09:15",
    "09:30",
    "09:45",
    "10:00",
    "10:15",
    "10:30",
    "10:45",
    "11:00",
    "11:15",
    "11:30",
    "11:45",
  ];

  const pmTimes = [
    "1:00",
    "1:15",
    "1:30",
    "1:45",
    "2:00",
    "2:15",
    "2:30",
    "2:45",
    "3:00",
    "3:15",
    "3:30",
    "3:45",
    "4:00",
    "4:15",
    "4:30",
    "4:45",
    "5:00",
    "5:15",
    "5:30",
    "5:45",
    "6:00",
    "6:15",
    "6:30",
    "6:45",
  ];

  const availableTimes = selectedTimeType === "AM" ? amTimes : pmTimes;

  const handleDayClick = (date) => {
    setSelectedDate(date.toISOString().slice(0, 10))
  };

  const handleSetINterviewSchedule = async () => {
    dispatch(setIsLoading(true));
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          scheduled_at: `${selectedDate} ${selectedTime}`,
          duration: '15',
          via: 'Zoom App',
          interview_type: 'One on One',
        }
      }

      const response = await setInterviewSchedule(data);
      if (response.status === 201) {
        navigate("/registration-success")
      }
      else {
        setErrorMessage("Failed to set your interview schedule. Please try again.");
      }
    }
    catch (error) {
      console.log("Error setting youy interview: ", error);
    }
    finally {
      dispatch(setIsLoading(false));
    }
  }

  const handleTimeTypeBtnClick = (e) => {
    setSelectedTimeType(e.target.innerText);
    setSelectedTime("");
  };

  return (
    <div className="flex justify-center bg-white">
      <div className="mt-16 w-[820px] max-w-full max-md:w-full max-md:mt-10 flex flex-col md:flex-row gap-10">
        <div className="w-full md:w-6/12">
          <div className="px-5 py-1 text-sm font-bold leading-4">
            <div className="cursor-pointer text-base leading-6 text-neutral-400">
              &lt; Back
            </div>
            <div className="mt-4 text-2xl tracking-tight text-blue-900">
              Set Interview Schedule
            </div>
            <div className="flex items-center gap-5 mt-4 text-sm font-light leading-5 text-[#A4A4A4]">
              <div className="flex gap-2">
                <img
                  alt="Interview type"
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/e16fae841bee600ee2cdfd708c51ad53a4af563ec2c020bf617c0d835eeffe69?apiKey=3ae96302e69649f5be368f89230b0596&"
                  className="w-4 h-4 shrink-0"
                />
                <div>One to one</div>
              </div>
              <div className="flex gap-2">
                <FaClock className="my-auto" />
                <div>15 min</div>
              </div>
              <div className="flex gap-2">
                <BsCameraVideoFill className="my-auto text-[#A4A4A4]" />
                <div>Zoom Meeting</div>
              </div>
            </div>
            <div className="mt-4 border-t border-neutral-100" />
            <div className="mt-4">
              <Calendar
                onChange={onChange}
                value={value}
                onClickDay={handleDayClick}
                tileClassName={({ date, view }) => {
                  const now = new Date();
                  const isSelected = selectedDate && selectedDate.getTime() === date.getTime();
                  if (view === "month") {
                    if (isSelected) {
                      return "bg-blue-900 text-white rounded-full";
                    } else if (date >= now) {
                      return "bg-green-500 text-white rounded-full";
                    }
                  }
                }}
                tileDisabled={({ date, view }) => {
                  const now = new Date();
                  return view === "month" && date < now;
                }}
              />
            </div>
          </div>
        </div>

        <div className="w-full md:w-6/12">
          <div className="px-5 text-sm leading-4">
            <div className="text-base leading-6 text-neutral-400">
              Australia Time
            </div>
            <div className="mt-1 text-base font-semibold leading-5 tracking-tight text-blue-900">
              Select your preferred time on {selectedDate.toLocaleDateString('en-AU', { month: 'long', day: 'numeric', year: 'numeric' })}
            </div>
            <div className="mt-4 border-t border-neutral-100" />
            <div className="grid grid-cols-2 gap-4 mt-4">
              {availableTimes.map((time) => (
                <button
                  key={time}
                  className={`px-7 py-2.5 rounded-lg text-md font-semibold ${selectedTime === time ? "bg-blue-900 text-white" : "bg-[#EFF4FF] text-blue-900"}`}
                  onClick={() => setSelectedTime(time)}
                >
                  {time}
                </button>
              ))}
            </div>
            {errorMessage && (
              <div className="mt-2 text-red-500">{errorMessage}</div>
            )}
            <div className="flex flex-col mt-10 gap-4">
              <button
                onClick={handleSetINterviewSchedule}
                className="py-2.5 text-lg font-semibold text-white bg-blue-900 rounded-3xl"
              >
                Set Interview Schedule
              </button>
              <button className="py-2.5 text-lg font-semibold text-white bg-blue-700 rounded-3xl">
                Interview Schedules
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Interview;