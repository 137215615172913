import React, { useState, useCallback, useEffect } from 'react';
import { FaImage, FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../actions';
import { MdUploadFile } from "react-icons/md";
import useUploadDocument from "../../api/customHook/useUploadDocument";
import { useNavigate } from "react-router-dom";
import {
  validateDocument,
  compileDocuments,
  deleteDocument,
  resetDocument,
} from "../../api/apiCall";

function UploadDocuments() {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const isMinor = localStorage.getItem("isMinor");
  const [nextPage, setNextPage] = useState({stage1: false, stage2: false, stage3: false});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isModalOpen,
    isCheckboxChecked,
    setIsCheckboxChecked,
    isCheckboxChecked2,
    setIsCheckboxChecked2,
    openModal,
    closeModal,
    responseMessage,
    // fileListID,
    fileListUtilityBill,
    fileListSelfie,
    onFileIdDrop,
    onFileUtilityBillDrop,
    onFileSelfieDrop,
    removeUtilityBillFile,
    removeSelfieFile,
  } = useUploadDocument();

  const [step, setStep] = useState(0);
  const [fileListID, setFileListID] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [validIdCount, setValidIdCount] = useState({front: 0, back: 0});
  const [error, setError] = useState("");

  useEffect(() => {
    const resetData = async () => {
      let data = {
        id: user.id,
        token: token,
      }

      try {
        const response = await resetDocument(data);
        if (response.status === 200) {
          setFileListID([]);
        }
      } catch (error) {
        console.error('Error resetting documents:', error);
      }
    };

    resetData();
  }, []);

  useEffect(() => {
    console.log(uploadedFiles)
    let bill = 0;
    let idFront = 0;
    let idBack = 0;
    let selfie = 0;

    uploadedFiles.map((file) => {
      if(file.file_category === 'BILLING'){
        bill += 1;
      }
      if(file.file_category === 'ID-FRONT'){
        idFront += 1;
        if(idFront === 2){
          setValidIdCount((prevCount) => ({...prevCount, front: 2}))
        }
      }
      if(file.file_category === 'ID-BACK'){
        idBack += 1;
        if(idBack === 2){
          setValidIdCount((prevCount) => ({...prevCount, back: 2}))
        }
      }
      if(file.file_category === 'SELFIE'){
        selfie += 1;
      }
    })

    if(bill === 1){
      setNextPage((...prev) => ({...prev, stage1: true}))
    }
    if(idFront === 2 && idBack === 2){
      setNextPage((...prev) => ({...prev, stage2: true}))
    }
    if(selfie === 1){
      setNextPage((...prev) => ({...prev, stage3: true}))
    }
    
  }, [uploadedFiles])

  const handleDrop = useCallback(async (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const result = await validateFile(files[0]);
    if (result) {
      processFiles(files, result);
    }
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleFileSelect = useCallback(async (event) => {
    const files = Array.from(event.target.files);
    const result = await validateFile(files[0]);
    if (result) {
      processFiles(files, result);
    }
  }, []);

  const removeSelectedFileID = (indexToRemove) => {
    setFileListID((prevFiles) =>
      prevFiles.filter((file, index) => index !== indexToRemove)
    );
  };

  const processFiles = (files, result) => {
    const newFiles = files.map((file) => ({
      id: Date.now() + file.name,
      name: file.name,
      type: file.type.split('/')[1],
      file: file,
      file_id: result.id,
      file_category: result.type
    }));
    setFileListID((prevIds) => [...prevIds, result.id]);
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const validateFile = async (file) => {
    setError("");
    dispatch(setIsLoading(true));
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          document: file,
        }
      }

      const response = await validateDocument(data);
      if(response.data.data.type === 'UNKNOWN'){
        setError("Invalid document. Please upload what is required.")
        return false;
      }
      return response.data.data;
    }
    catch (error) {
      console.log(error);
      setError(error.response?.data?.message);
      return false;
    }
    finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleCompileDocuments = async () => {
    dispatch(setIsLoading(true));
    let data = {
      id: user.id,
      token: token,
    }

    try {
      const response = await compileDocuments(data);
      if (response.status === 200) {
        if (!isMinor) {
          navigate('/face-recognition');
        }
        else {
          navigate('/payment');
        }
      }
    }
    catch (error) {
      if (error.response.data.message === "") {
        const response = await resetDocument(data);
        if (response.status === 200) {
          setFileListID([]);
        }
        console.log("Reset response: ", response);
      }
    }
    finally {
      dispatch(setIsLoading(false));
    }
  }

  const handleDeleteDocument = async (fileId) => {
    dispatch(setIsLoading(true));
    try {
      let data = {
        id: user.id,
        token: token,
        documentId: fileId.file_id
      }

      const response = await deleteDocument(data);
      if (response.status === 200) {
        setUploadedFiles(uploadedFiles.filter((item) => item.file_id !== fileId.file_id));
      }
    }
    catch (error) {
      console.log("Error deleting document: ", error);
    }
    finally{
      dispatch(setIsLoading(false));
    }
  }

  const nextStep = () => {
    if (step < 3) setStep(step + 1);
  }

  const handleSubmit = async () => {
    await handleCompileDocuments();
  }

  const getFileTypeLabel = (type) => {
    switch (type) {
      case 'image/jpeg':
      case 'image/png':
        return 'IMG';
      case 'application/pdf':
        return 'PDF';
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'DOC';
      default:
        return 'FILE';
    }
  };

  const renderUploadedFiles = (file, index) => {
    return (
      <div key={index} className="flex items-center mt-2">
        <div className="flex items-center justify-center w-8 h-8 rounded-full text-white text-sm mr-3 bg-blue-500">
          {getFileTypeLabel(file.file.type)}
        </div>
        <div className="flex-grow">
          <p className="text-sm font-semibold">{file.file.name}</p>
          <p className="text-xs text-gray-500">{(file.file.size / 1024 / 1024).toFixed(1)}mb</p>
        </div>
        <FaCheck className="text-green-400 ml-3"/>
      </div>
    );
  };
  
  return (
    <div className='bg-[#F9FAFB] py-12 h-full'>
      <div className="flex flex-col mx-auto my-12 max-w-[750px] max-sm:px-10 bg-white rounded-lg py-10 border border-gray-200">
        <div className="px-10">
          <div className="flex gap-4 justify-center items-center mb-8">
            {[0, 1, 2].map((s) => (
              <div
                key={s}
                className={`w-4 h-4 rounded-full ${s < step ? 'bg-[#0082ba]' : s === step ? 'border-4 border-[#0082ba]' : 'border-4 border-gray-300'
                  }`}
              ></div>
            ))}
          </div>

          {step === 0 && (
            <>
              <div className="font-bold text-[#1c3775] text-2xl whitespace-nowrap">
                Additional Document Requirements
              </div>
              <div className="font-bold text-md mt-8 text-neutral-600">
                Please upload the following:
              </div>
              <div className="text-md mt-2 text-neutral-700 ml-1">
                <ul>
                  <li>• Amenity Bill</li>
                </ul>
              </div>
              <div className="font-bold text-lg mt-6 text-neutral-600">
                Billing Statement
              </div>
              <div className="text-md text-neutral-700">
                Upload your latest billing statement
              </div>
              <div className="text-md mt-6 text-neutral-700">
                Note: Statement must not be more than 30 days
              </div>
              {error && <small className='text-red-500 text-sm mt-2 flex justify-center'>{error}</small>}
              {!nextPage.stage1 && (
                <div
                  className="container border-neutral-300 border-2 rounded-2xl mt-2 relative hover:opacity-80"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <div className="py-16 text-neutral-400">
                      <div className="text-center flex flex-col justify-center items-center text-lg">
                        Drag or Upload your documents here <br />
                        <span className="text-sm">(jpg, png, pdf)</span>
                      </div>
                  </div>
                  <input
                    type="file"
                    value=""
                    onChange={handleFileSelect}
                    className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                  />
                </div>
              )}
              
              <div className="flex gap-4 mt-2">
                {uploadedFiles.length > 0 &&
                  uploadedFiles.map((file, index) => (
                    (file.file_category === "BILLING") && (
                      <div
                        key={index}
                        className="flex items-center bg-[#f8f8f8] px-4 py-2 gap-4 rounded-md"
                      >
                        <FaImage className="text-[#0d88be]" />
                        <div>
                          {file.file.name.length > 12
                            ? `${file.file.name.slice(0, 12)}...`
                            : file.file.name}
                        </div>
                        <IoMdClose
                          onClick={() => handleDeleteDocument(file)}
                          className="cursor-pointer"
                        />
                      </div>
                    )
                  ))}
              </div>
              <div className={`text-neutral-400 mt-1 ${responseMessage.utils === "User File Uploaded" ||
                responseMessage.utils === "Uploading File..." ||
                responseMessage.utils === "User File Removed"
                ? "text-green-600"
                : "text-red-600"
                }`}
              >
                {responseMessage?.utils}
              </div>
              <div className="mt-4">
                {uploadedFiles.length > 0 &&
                  uploadedFiles.map((file, index) => (
                    (file.file_category === "BILLING") && (
                      renderUploadedFiles(file, index)
                    )
                  ))}
              </div>
              <hr className='mt-6'/>
              <button
                onClick={nextStep}
                className={`opacity-100 items-center mt-6 bg-[#1c3775] text-white px-20 py-4 rounded-full font-semibold text-lg w-full ${!nextPage.stage1 ? 'opacity-[50%] cursor-not-allowed':'cursor-pointer'}`}
                disabled={!nextPage.stage1}
              >
                Continue
              </button>
            </>
          )}

          {step === 1 && (
            <>
              <div className="font-bold text-lg mt-3 text-neutral-600">
                Government Valid ID
              </div>
              <div className="text-md mt-2 text-neutral-700 mb-6">
                A current and valid <span className='font-bold'>(not expired or cancelled)</span> government-issued document that includes the applicant's photo and signature.
              </div>
              <hr />
              {error && <small className='text-red-500 text-sm mt-2 flex justify-center'>{error}</small>}
              <div className="text-neutral-400 mt-6">{`Note: (India) Bharat Drivers License are not accepted`}</div>
              <div className="mt-2">
                {validIdCount.front !== 2 && (
                  <div
                    className="container border-neutral-300 border-2 rounded-2xl mt-2 relative hover:opacity-80"
                    onDrop={(event) => handleDrop(event, 'ID-FRONT')}
                    onDragOver={handleDragOver}
                  >
                    <div className="py-16 text-neutral-400">
                      <div className="text-center flex flex-col justify-center items-center text-lg">
                        Upload Front of your ID <br />
                        <span className="text-sm">(jpg, png, pdf)</span>
                        <span className="text-sm mt-2">Image must not be larger than 5mb</span>
                      </div>
                    </div>
                    <input
                      type="file"
                      onChange={(event) => handleFileSelect(event, 'ID-FRONT')}
                      className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                    />
                  </div>
                )}
                
                <div className="flex gap-4 mt-2">
                    {uploadedFiles.length > 0 &&
                      uploadedFiles.map((file, index) => (
                        (file.file_category === "ID-FRONT") && (
                          <div
                            key={index}
                            className="flex items-center bg-[#f8f8f8] px-4 py-2 gap-4 rounded-md"
                          >
                            <FaImage className="text-[#0d88be]" />
                            <div>
                              {file.file.name.length > 12
                                ? `${file.file.name.slice(0, 12)}...`
                                : file.file.name}
                            </div>
                            <IoMdClose
                              onClick={() => handleDeleteDocument(file)}
                              className="cursor-pointer"
                            />
                          </div>
                        )
                      ))
                    }
                </div>
              </div>
              <div className="mt-5">
                {validIdCount.back !== 2 && (
                  <div
                    className="container border-neutral-300 border-2 rounded-2xl mt-2 relative hover:opacity-80"
                    onDrop={(event) => handleDrop(event, 'ID-BACK')}
                    onDragOver={handleDragOver}
                  >
                    <div className="py-16 text-neutral-400">
                      <div className="text-center flex flex-col justify-center items-center text-lg">
                        Upload Back of your ID <br />
                        <span className="text-sm">(jpg, png, pdf)</span>
                        <span className="text-sm">Image must not be larger than 5mb</span>
                      </div>
                    </div>
                    <input
                      type="file"
                      onChange={(event) => handleFileSelect(event, 'ID-BACK')}
                      className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                    />
                  </div>
                )}
                
                <div className="flex gap-4 mt-2">
                  {uploadedFiles.length > 0 &&
                    uploadedFiles.map((file, index) => (
                      (file.file_category === "ID-BACK") && (
                        <div
                          key={index}
                          className="flex items-center bg-[#f8f8f8] px-4 py-2 gap-4 rounded-md"
                        >
                          <FaImage className="text-[#0d88be]" />
                          <div>
                            {file.file.name.length > 12
                              ? `${file.file.name.slice(0, 12)}...`
                              : file.file.name}
                          </div>
                          <IoMdClose
                            onClick={() => handleDeleteDocument(file)}
                            className="cursor-pointer"
                          />
                        </div>
                      )
                    ))
                  }
                </div>
              </div>
              <div
                className={`text-neutral-400 mt-1 ${responseMessage.id === "User File Uploaded" ||
                  responseMessage.id === "Uploading File..." ||
                  responseMessage.id === "User File Removed"
                  ? "text-green-600"
                  : "text-red-600"
                  }`}
              >
                {responseMessage?.id}
              </div>
              <div className="mt-5">
                  {uploadedFiles.length > 0 &&
                    uploadedFiles.map((file, index) => (
                      (file.file_category === "ID-FRONT" || file.file_category === "ID-BACK") && (
                        renderUploadedFiles(file, index)
                      )
                    ))
                  }
              </div>
              <hr className='mt-6'/>
              <button
                onClick={nextStep}
                className={`opacity-100 items-center mt-6 bg-[#1c3775] text-white px-20 py-4 rounded-full font-semibold text-lg w-full ${!nextPage.stage2 ? 'opacity-[50%] cursor-not-allowed':'cursor-pointer'}`}
                disabled={!nextPage.stage2}
              >
                Continue
              </button>
            </>
          )}

          {step === 2 && (
            <>
              <div className="font-bold text-lg mt-3 text-neutral-600">
                Upload a Selfie
              </div>
              <div className="text-md mt-2 mb-4 text-neutral-700">
                Upload a clear selfie of yourself
              </div>
              <hr />
              {error && <small className='text-red-500 text-sm mt-2 flex justify-center'>{error}</small>}
              {!nextPage.stage3 && (
                <div
                  className="container border-neutral-300 border-2 rounded-2xl mt-6 relative hover:opacity-80"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <div className="py-16 text-neutral-400">
                    <div className="text-center text-lg">
                      Drag & Drop or<span className="text-sky-500"> &nbsp; choose &nbsp;</span>image to upload<br/>
                      <span className="text-sm">(jpg, png, pdf)</span><br/>
                      <span className="text-sm">Image must not be larger than 5mb</span>
                    </div>
                  </div>
                  <input
                    type="file"
                    value=""
                    onChange={handleFileSelect}
                    className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                  />
                </div>
              )}
              
              <div className="flex gap-4 mt-2">
                {fileListID.length > 0 &&
                  fileListID.map((file, index) => (
                    (file.file_category === "SELFIE") && (
                      <div
                        key={index}
                        className="flex items-center bg-[#f8f8f8] px-4 py-2 gap-4 rounded-md"
                      >
                        <FaImage className="text-[#0d88be]" />
                        <div>
                          {file.file.name.length > 12
                            ? `${file.file.name.slice(0, 12)}...`
                            : file.file.name}
                        </div>
                        <IoMdClose
                          onClick={() =>
                            handleDeleteDocument(file)
                          }
                          className="cursor-pointer"
                        />
                      </div>
                    )
                  ))}
              </div>
              <div
                className={`text-neutral-400 mt-1 ${responseMessage.selfie === "User File Uploaded" ||
                  responseMessage.selfie === "Uploading File..." ||
                  responseMessage.selfie === "User File Removed"
                  ? "text-green-600"
                  : "text-red-600"
                  }`}
              >
                {responseMessage?.selfie}
              </div>
              <div className="mt-4">
                {fileListID.length > 0 &&
                  fileListID.map((file, index) => (
                    (file.file_category === "SELFIE") && (
                      renderUploadedFiles(file, index)
                    )
                  ))}
              </div>
              <div className="text-md mt-8 text-neutral-700 flex gap-1 items-center">
                <FaCheck className="text-green-400" /> Ensure your face is clearly visible and well-lit.
              </div>
              <div className="text-md mt-2 text-neutral-700 flex gap-1 items-center">
                <FaCheck className="text-green-400" /> Avoid any accessories such as hats, sunglasses, or masks.
              </div>
              <button
                onClick={handleSubmit}
                className={`opacity-100 cursor-pointer items-center mt-6 bg-[#1c3775] text-white px-20 py-4 rounded-full font-semibold text-lg w-full ${!nextPage.stage3 ? 'opacity-[50%] cursor-not-allowed':'cursor-pointer'}`}
                disabled={!nextPage.stage3}
              >
                Submit
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default UploadDocuments;
