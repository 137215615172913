import React, {useState} from "react";
import {
  contactAdmin
} from "../../../api/apiCall.js";

function Help() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const [typeOfMessage, setTypeOfMessage] = useState(null);
  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState({ success: null, message: null })
  const [errors, setErrors] = useState({ messageType: null, subject: null, message: null });
  const [sending, setSending] = useState(false);

  const validate = () => {
    setErrors({ messageType: null, subject: null, message: null });
    let resp = true;
    if(!typeOfMessage){
      setErrors((prev) => ({ ...prev, messageType:"Type of message is required" }));
      resp = false;
    }
    if(typeOfMessage === "Complaint" && !subject){
      setErrors((prev) => ({...prev, subject:"Message is required" }));
      resp = false;
    }
    if(!message){
      setErrors((prev) => ({...prev, message:"Message is required" }));
      resp = false;
    }

    return resp;
  }

  const handleSend = async () => {
    setResponse({ success: null, message: null });
    const validated = validate();
    if(!validated) return;

    setSending(true);
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          message: message,
          subject: subject
        }
      }
      switch(typeOfMessage){
        case "Compliment":
          data.type = "compliments";
          break;
        case "Feedback":
          data.type = "feedbacks";
          break;
        case "Complaint":
          data.type = "complaints";
          break;

        default:
          break;
      }
      console.log(data)
      const response = await contactAdmin(data);
      if (response.status === 201) {
        setResponse({ success: true, message: response.data.message })
      }
    }
    catch(error){
      console.log("Error sending feedback: ", error);
      setResponse({ success: false, message: error.response?.data?.message })
    }
    finally{
      setSending(false);
    }
  }


  return (
    <div className=" min-h-[87vh]">
      <div className="w-full px-5">
        <div className="self-start text-lg font-bold tracking-tight leading-5 text-blue-900 max-md:max-w-full">
          Contact Admin
        </div>
        <div className="max-w-[500px] m-auto">
          {!response.success && (
            <div className="mt-6 tracking-tight text-center text-red-500">
              {response.message}
            </div>
          )}
          {response.success && (
            <div className="mt-6 tracking-tight text-center text-green-500">
              {response.message}
            </div>
          )}
          <div className="flex flex-col  items-center px-8 text-base leading-4 text-neutral-600 max-md:px-5 max-md:mt-8 max-md:max-w-full">
            <div className="mt-6 tracking-tight self-start">
              Type of Message
            </div>
            <div className="flex flex-col items-center px-8 text-base leading-4 text-neutral-600 max-md:px-5 max-md:mt-8 max-md:max-w-full">
              <div className="flex gap-5 justify-between px-2 mt-4 max-w-full whitespace-nowrap leading-[121%] w-[407px] max-md:pr-5">
                <label className="flex gap-2 py-px items-center cursor-pointer">
                  <input
                    type="radio"
                    value="compliment"
                    checked={typeOfMessage === "Compliment"}
                    onChange={() => setTypeOfMessage("Compliment")}
                    className="peer shrink-0 w-4 h-4 appearance-none rounded-full border-2 border-gray-300 cursor-pointer checked:bg-teal-400"
                  />
                  <span>Compliment</span>
                </label>
                <label className="flex gap-2 py-0.5 items-center cursor-pointer">
                  <input
                    type="radio"
                    value="feedback"
                    checked={typeOfMessage === "Feedback"}
                    onChange={() => setTypeOfMessage("Feedback")}
                    className="peer shrink-0 w-4 h-4 appearance-none rounded-full border-2 border-gray-300 cursor-pointer checked:bg-teal-400"
                  />
                  <span className="my-auto">Feedback</span>
                </label>
                <label className="flex gap-2 py-px items-center cursor-pointer">
                  <input
                    type="radio"
                    value="complaint"
                    checked={typeOfMessage === "Complaint"}
                    onChange={() => setTypeOfMessage("Complaint")}
                    className="peer shrink-0 w-4 h-4 appearance-none rounded-full border-2 border-gray-300 cursor-pointer checked:bg-teal-400"
                  />
                  <span>Complaint</span>
                </label>
              </div>
            </div>
            {errors.messageType && (
              <smal className="self-start text-red-500 text-xs flex gap-1 mt-1">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z" stroke="#F94B4B" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                {errors.messageType}
              </smal>
            )}
            
            {typeOfMessage === 'Complaint' && (
              <>
                <div className="mt-6 text-base self-start tracking-tight ">
                  Subject
                </div>
                <input 
                  type="text" 
                  value={subject}
                  className="px-4 py-2 shrink-0 mt-2 max-w-full rounded-2xl border border-solid border-neutral-300 w-[407px]" 
                  placeholder="Serial Number" 
                  onChange={(e) => setSubject(e.target.value)}
                />
                {errors.subject && (
                  <smal className="self-start text-red-500 text-xs flex gap-1 mt-1">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z" stroke="#F94B4B" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    {errors.subject}
                  </smal>
                )}
              </>
            )}
            
            <div className="mt-6 text-base self-start tracking-tight ">
              Message
            </div>
            <textarea 
              className="px-4 py-2 shrink-0 mt-2 max-w-full rounded-2xl border border-solid border-neutral-300 h-[180px] w-[407px]" 
              onChange={(e) => setMessage(e.target.value)}
            />
            {errors.message && (
              <smal className="self-start text-red-500 text-xs flex gap-1 mt-1">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z" stroke="#F94B4B" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                {errors.message}
              </smal>
            )}
            <div className="flex gap-4 justify-center mt-4 max-w-full font-semibold text-white w-[407px]">
              <div className="cursor-pointer justify-center px-6 py-4 whitespace-nowrap bg-sky-600 rounded-2xl max-md:px-5">
                Cancel
              </div>
              <button 
                className={`flex-1 text-center justify-center items-center px-6 py-4 bg-blue-900 rounded-2xl max-md:px-5 ${sending ? 'opacity-[50%] cursor-not-allowed':'cursor-pointer'}`}
                onClick={() => handleSend()}  
                disabled={sending}
              >
                {sending ? "Sending...":"Send to Admin"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
