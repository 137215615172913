import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRegisteredCoins, updateCoin } from "../../../api/apiCall.js";

function CoinProfileMain() {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCoins, setFilteredCoins] = useState([]);
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCoin, setSelectedCoin] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered = coins.filter(
      (coin) =>
        coin.name.toLowerCase().includes(query.toLowerCase()) ||
        coin.ticker.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCoins(filtered);
  };

  const handleSearchClick = () => {
    const filtered = coins.filter(
      (coin) =>
        coin.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        coin.ticker.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCoins(filtered);
  };

  const handleCoinClick = (coin) => {
    navigate(`/dashboard/coin-profile/coin/${coin.id}`, {
      state: { coin },
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };

  const handleGetRegisteredCoins = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
      };

      const response = await getRegisteredCoins(data);
      if (response.status === 200) {
        setCoins(response.data);
        setFilteredCoins(response.data);
      } else {
        setError("Failed to fetch coins");
      }
    } catch (error) {
      console.error("Error getting registered coins: ", error);
      setError("Error fetching coins");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCoin = async (coin) => {
    const formData = new FormData();
    formData.append('is_anonymous', coin.is_anonymous ? 1 : 0);

    try {
      const data = {
        id: user.id,
        token: token,
        payloads: formData,
        coinId: coin.id, // Assuming each coin has an 'id'
      };

      const response = await updateCoin(data);

      if (response.status === 200) {
        console.log(response);
        // Optionally refresh or update local state here
        handleGetRegisteredCoins(); // Refresh the coin list
      }
    } catch (error) {
      console.log("Error updating coin: ", error);
    }
  };

  const handleAnonymousToggle = (coin) => {
    // Toggle the is_anonymous value between 1 and 0
    const updatedCoin = { 
      ...coin, 
      is_anonymous: coin.is_anonymous === 1 ? 0 : 1 
    };
  
    // Update the local state
    setCoins(coins.map(c => c.id === coin.id ? updatedCoin : c));
  
    // Send the updated coin data to the server
    handleUpdateCoin(updatedCoin);
  };
  

  useEffect(() => {
    const initalFetch = async () => {
      setLoading(true);
      await handleGetRegisteredCoins();
    }

    initalFetch();
  }, [location]);

  return (
    <div className="w-full">
      <div className="search">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          className="search-input border-2 max-w[45%] w-[45%] rounded-md px-2 py-1.5 mb-20 max-md:mt-5"
          placeholder="Search Coin Name"
        />
        <button
          onClick={handleSearchClick}
          className="bg-devRegDarkBlue px-4 font-semibold py-1.5 ml-3 rounded-xl text-white"
        >
          Search
        </button>
      </div>

      <div className="title flex justify-between my-5 items-center">
        <div className="font-bold text-sm18 text-devRegDarkBlue">
          Registered Coins
        </div>
        <button
          onClick={() => {
            navigate("/dashboard/coin-profile/register-coin");
            window.scrollTo(0, 0);
          }}
          className="bg-devRegDarkBlue py-2 font-semibold px-4 rounded-xl text-white"
        >
          Register A Coin
        </button>
      </div>

      {/* COIN DATA HEADER */}
      <div className="coin-container flex text-devRegBlack py-4 bg-[#f1f1f1] border-t-2 max-lg:text-sm14 font-semibold items-center text-center text-sm15">
        <div className="w-[20%] max-lg:w-[25%]">#</div>
        <div className="w-[20%] max-lg:w-[25%] text-start pl-10">NAME</div>
        <div className="w-[20%] max-lg:w-[25%]">TICKER</div>
        <div className="w-[20%] max-lg:w-[25%]">ANONYMOUS</div>
      </div>
      {/* COIN DATA LOOP */}
      {!loading ? (
        filteredCoins.length > 0 ? (
          filteredCoins.map((item, index) => (
            <div
              key={item.id} // Assuming each coin has a unique 'id'
              className="content-1 flex items-center text-center cursor-pointer py-2 border-b-2 text-sm15 text-devRegBlack"
              onClick={() => handleCoinClick(item)}
            >
              <div className="w-[20%] max-lg:w-[25%] font-semibold">
                {index + 1}
              </div>
              <div className="w-[20%] max-lg:w-[25%] my-auto flex items-center gap-2">
                <img src={item.icon} className="max-w-[40px] max-h-[40px]" alt={item.name} />
                {item.name}
              </div>
              <div className="w-[20%] max-lg:w-[25%] font-semibold">
                {item.ticker}
              </div>
              <div
                className="w-[20%] max-lg:w-[25%] text-stone-400"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="inline-flex items-center mx-3 mt-1 space-x-4 me-5 cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={item.is_anonymous === 1} // Check if `is_anonymous` is 1 to set the checkbox as checked
                    onChange={() => handleAnonymousToggle(item)} // Toggle anonymous state
                  />
                  <div className={`relative w-11 h-6 rounded-full ${item.is_anonymous === 1 ? "bg-green-300" : "bg-gray-200"}`}>
                    <div className={`absolute top-0.5 start-[2px] bg-white border-gray-300 border rounded-full h-5 w-5 transition-all ${item.is_anonymous === 1 ? "translate-x-full" : ""}`}></div>
                  </div>
                  <span className="mx-3 text-sm font-medium text-gray-900 dark:text-gray-400">
                    {item.is_anonymous === 1 ? "Yes" : "No"}
                  </span>
                </label>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-devRegBlack py-4">
            No registered coins found
          </div>
        )
      ):(
        <div className="mt-10 flex flex-col items-center justify-center z-50 bg-white/75">
          <div className="relative flex justify-center items-center">
            <div className="h-10 w-10 rounded-full border-t-2 border-b-2 border-gray-200"></div>
            <div className="absolute top-0 left-0 h-10 w-10 rounded-full border-t-2 border-b-2 border-devRegBlue animate-spin"></div>
          </div>
        </div>
      )}
      
    </div>
  );
}

export default CoinProfileMain;
