import * as React from "react";
import { useNavigate } from "react-router-dom";

function Footer() {
    const navigate = useNavigate();

    const handleClick = (to) => {
        navigate(to);
        window.scrollTo(0, 0);
    };
    return (
        <div className="flex overflow-hidden flex-col px-32 bg-white border-t border-neutral-300 max-md:px-5">
            <div className="flex overflow-hidden flex-col py-8 w-full max-md:max-w-full">
                <div className="flex flex-col items-center w-full max-md:max-w-full">
                    <div className="flex flex-col max-w-full w-[143px]">
                        <img
                            loading="lazy"
                            src="/logoFooter.png"
                            className="object-contain w-full aspect-[3.57]"
                            onClick={() => {
                                navigate("/");
                                window.scrollTo(0, 0);
                              }}
                        />
                    </div>
                </div>
                <div className="flex flex-col justify-center mt-8 w-full text-sm text-gray-500 max-md:max-w-full">
                    <div className="flex flex-wrap gap-6 justify-center items-end w-full max-md:max-w-full">
                        <div onClick={() => handleClick('/why-register')} className="cursor-pointer">Why DevReg?</div>
                        <div onClick={() => handleClick('/benefits')} className="cursor-pointer">Benefit Page</div>
                        <div onClick={() => handleClick('/about-profile')} className="cursor-pointer">About Profile</div>
                        <div onClick={() => handleClick('/requirements')} className="cursor-pointer">Requirements</div>
                        <div onClick={() => handleClick('/about-us')} className="cursor-pointer">About Us</div>
                        <div onClick={() => handleClick('/contact')} className="cursor-pointer">Contact Us</div>
                    </div>
                </div>
                <div className="flex flex-col mt-8 w-full max-md:max-w-full">
                    <div className="flex gap-6 items-start self-start max-md:self-center max-sm:self-center max-sm:ml-px">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1901a358f8092a381235ece0fb0d95a6570db23fc758bacf00c8c16d0d302f41?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c"
                            className="object-contain shrink-0 w-5 aspect-square"
                        />
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1a1eb0b62d062c5ca82111e2c3cf19789c9938cdde87b67b0714a59717bdcc3f?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c"
                            className="object-contain shrink-0 w-5 aspect-square"
                        />
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/83c7ffd13139d99fa3e0d76847d2c5c8da55691f77b93fc0682abe6befe7b92e?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c"
                            className="object-contain shrink-0 w-5 aspect-square"
                        />
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b293382a34a136575cce54197a69c55ca982775569524959f75a64194c9086bb?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c"
                            className="object-contain shrink-0 w-5 aspect-square"
                        />
                    </div>
                    <div className="flex flex-wrap gap-10 justify-between items-center pt-4 mt-4 w-full text-sm text-gray-500 border-t border-solid min-h-[52px] max-md:max-w-full">
                        <div className="flex gap-4 items-center self-stretch my-auto min-w-[240px] max-md:mx-auto max-md:justify-center">
                            <div onClick={() => handleClick('/terms-and-conditions')} className="self-stretch my-auto max-sm:mx-auto max-sm:text-center cursor-pointer">
                                Terms of Service
                            </div>
                            <div onClick={() => handleClick('/anti-bribery-policy')} className="self-stretch my-auto max-sm:mx-auto max-sm:text-center cursor-pointer">
                                Anti-Bribery & Corruption
                            </div>
                            <div onClick={() => handleClick('/privacy')} className="self-stretch my-auto max-sm:mx-auto max-sm:text-center cursor-pointer">
                                Privacy Policy
                            </div>
                        </div>
                        <div className="gap-2 my-auto min-w-[240px] w-[436px] self-stretch max-md:max-w-full text-end max-lg:text-start  max-md:text-center">
                            © 2024 Devreg. All Rights Reserved
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;