import * as React from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This is important for styling

function AboutProfile() {
    const [isOpen, setIsOpen] = React.useState(false);
    const [photoIndex, setPhotoIndex] = React.useState(0);

    const images = [
        "/Devreg_A0.jpg",
    ];

    return (
        <div className="flex overflow-hidden flex-col justify-center items-center pb-16 bg-[#F9FAFB]">
            <div className="flex overflow-hidden w-full justify-center py-16 px-10 bg-blue-900 min-h-[254px]">
                <div className="flex flex-col max-w-main w-full px-10 max-md:px-3">
                    <div className="max-sm:text-sm20 max-lg:text-[24px] max-md:text-center text-[33px] font-bold text-white">
                        ABOUT PROFILE
                    </div>
                    <div className="mt-6 max-sm:text-center text-white font-light text-sm15 max-sm:mt-2">
                        This sample profile showcases what your completed profile will look
                        like. Simply follow the prompts provided on the side of the screen;{" "}
                        <br />
                        they will guide you through each step of creating your profile,
                        providing helpful tips on what information to include and how to
                        best present yourself.
                    </div>
                </div>
            </div>
            <div className="max-md:px-6 flex flex-col mt-16 max-w-main max-md:mt-10 px-10 items-start max-md:items-start">
                <div className="flex flex-col">
                    <div className="flex gap-2 justify-center items-center self-start px-3 py-2 text-sm font-medium leading-none text-center text-green-500 bg-green-100 rounded-3xl">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/bea892b10e36bf92eb1dc90df2c97aa031f329de577c2db247ca6f3514e3927b?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c"
                            className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square"
                        />
                        <div className="self-stretch my-auto">Devreg Sample Profile</div>
                    </div>
                    <div className="mt-4 text-left font-bold text-2xl md:text-3xl text-devRegDarkBlue">
                        Profile Setup Guide
                    </div>
                </div>
                <img
                    loading="lazy"
                    src="/Devreg_A0.jpg"
                    className="object-contain w-full rounded-xl aspect-[0.61] cursor-pointer"
                    onClick={() => {
                        setIsOpen(true);
                    }}
                />
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
            </div>
        </div>
    );
}

export default AboutProfile;