import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import PhoneNumber from "../../../Registration/components/PhoneNumber";
import { 
  checkPassword,
  sendOtpPhone,
  verifyOtpPhone,
  sendOtpNewPhone,
  verifyOtpNewPhone,
} from "../../../../api/apiCall.js";


function ChangeNumber() {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorOtp, setErrorOtp] = useState(null);

  const [currentView, setCurrentView] = useState("verify-pass");
  const [password, setPassword] = useState("");
  const [phoneToken, setPhoneToken] = useState("");
  const [OtpOne, setOtpOne] = useState(null);
  const [OtpTwo, setOtpTwo] = useState(null);
  const [OtpThree, setOtpThree] = useState(null);
  const [OtpFour, setOtpFour] = useState(null);
  const [OtpFive, setOtpFive] = useState(null);
  const [OtpSix, setOtpSix] = useState(null);
  const [countdown, setCountdown] = useState(0);
  const [registrationPhoneNumber, setRegistrationPhoneNumber] = useState("");
  const [OtpOneNP, setOtpOneNP] = useState(null);
  const [OtpTwoNP, setOtpTwoNP] = useState(null);
  const [OtpThreeNP, setOtpThreeNP] = useState(null);
  const [OtpFourNP, setOtpFourNP] = useState(null);
  const [OtpFiveNP, setOtpFiveNP] = useState(null);
  const [OtpSixNP, setOtpSixNP] = useState(null);


  const handleCheckPassword = async () => {
    setIsLoading(true);
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          password: password
        }
      }
      const response = await checkPassword(data);
      if (response.status === 200) {
        setErrorMessage(null);
        setCurrentView("send-otp-to-number")
      }
    }
    catch(error){
      setErrorMessage(error.response.data.message);
      console.error("Error checking password: ", error);
    }
    finally{
      setIsLoading(false);
    }
  }

  const  maskPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== 'string') return 'Invalid phone number';
    if (phoneNumber.length < 3) return phoneNumber;
    if (phoneNumber === null) return '';
    const lastThreeDigits = phoneNumber.slice(-3);
    const maskedPart = '*'.repeat(phoneNumber.length - 3);
    const maskedPhoneNumber = maskedPart + lastThreeDigits;
    return maskedPhoneNumber;
  }

  const handleSendOtp = async () => {
    setIsLoading(true);
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          email: user.email,
          phone_number: user.phone_number
        }
      }
      const response = await sendOtpPhone(data);
      if (response.status === 200) {
        setCurrentView("verify-otp");
      }
    }
    catch(error){
      console.error("Error sending otp: ", error);
    }
    finally{
      setIsLoading(false);
    }
  }

  const handleResendOtp = async () => {
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          email: user.email,
          phone_number: user.phone_number
        }
      }
      const response = await sendOtpPhone(data);
      if (response.status === 200) {
        startCountdown();
        setCurrentView("verify-otp");
      }
    }
    catch(error){
      console.error("Error resending otp: ", error);
    }
  }

  const startCountdown = () => {
    setCountdown(30);
    const interval = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  }

  const handleVerifyOtpPhone = async () => {
    setIsLoading(true);
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          otp: `${OtpOne}${OtpTwo}${OtpThree}${OtpFour}${OtpFive}${OtpSix}`,
          email: user.email,
          is_change_phone_number: 1,
        }
      }
      console.log(data.payloads);
      const response = await verifyOtpPhone(data);
      if (response.status === 200) {
        setPhoneToken(response.data.token);
        setErrorOtp(null);
        setCurrentView("input-new-number");
      }
    }
    catch(error){
      console.error("Error verifying OTP: ", error);
      setErrorOtp(error.response.data.message);
    }
    finally{
      setIsLoading(false);
    }
  }

  const handlePhoneNumberChange = (phoneNumber) => {
    setRegistrationPhoneNumber(phoneNumber);
  }

  const handleSendOtpNewPhone = async () => {
    setIsLoading(true);
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          phone_number: '+'+registrationPhoneNumber,
          token: phoneToken,
        }
      }
      const response = await sendOtpNewPhone(data);
      if (response.status === 200) {
        // setCurrentView("verify-new-otp");
        setErrorOtp(null);
        localStorage.setItem('account_updated', true);
        user.phone_number = '+'+registrationPhoneNumber;
        localStorage.setItem('user', JSON.stringify(user));
        setCurrentView("success");
      }
    }
    catch(error){
      console.error("Error sending otp to new phone: ", error);
    }
    finally{
      setIsLoading(false);
    }
  }
  
  const handleResendOtpNewPhone = async () => {
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          phone_number: '+'+registrationPhoneNumber,
          email: user.email,
        }
      }
      const response = await sendOtpPhone(data);
      if (response.status === 200) {
        startCountdown();
        setCurrentView("verify-new-otp");
      }
    }
    catch(error){
      console.error("Error sending otp to new phone: ", error);
    }
  }

  const handleVerifyOtpNewPhone = async () => {
    setIsLoading(true);
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          otp: `${OtpOneNP}${OtpTwoNP}${OtpThreeNP}${OtpFourNP}${OtpFiveNP}${OtpSixNP}`,
          email: user.email,
          is_change_phone_number: 1,
        }
      }
      console.log(data.payloads);
      const response = await verifyOtpNewPhone(data);
      if (response.status === 200) {
        setErrorOtp(null);
        localStorage.setItem('account_updated', true);
        setCurrentView("success");
      }
    }
    catch(error){
      console.error("Error verifying OTP: ", error);
      setErrorOtp(error.response.data.message);
    }
    finally{
      setIsLoading(false);
    }
  }



  return (
    <div className=" min-h-[87vh]">
      <div className="w-full px-5">
        <div className="mt-12  flex  self-start items-center gap-2 tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
          <IoIosArrowBack size={20} />
          {` `}Change Phone Number
        </div>
        {/* =================== Change Number =================== */}
        {currentView === "verify-pass" && (
          <div className="flex flex-col items-center px-8 pb-20 text-base max-md:px-5 max-md:mt-0 max-md:max-w-full">
            <small className="text-red-600">{errorMessage}</small>
            <input
              className="justify-center items-start px-4 py-3 mt-4 max-w-full text-base leading-5 rounded-xl border border-solid placeholder-text-stone-300 border-neutral-300  w-[346px] max-md:px-5 max-md:mt-2"
              placeholder="Enter Password"
              onChange={(e) => setPassword(e.target.value)}
            />

            <div className="flex gap-4 mt-6 max-w-full font-semibold text-white whitespace-nowrap w-[346px]">
              <div
                className="justify-center cursor-pointer px-6 py-4 bg-sky-600 rounded-2xl max-md:px-5"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </div>
              <button
                className={`flex-1 text-center justify-center items-center px-6 py-4 rounded-2xl max-md:px-5 bg-blue-900  ${
                  password === ""
                    ? "opacity-50 cursor-not-allowed"
                    : "cursor-pointer"
                } ${isLoading ? "cursor-not-allowed":""}`}
                onClick={handleCheckPassword}
                disabled={password === "" || isLoading}
              >
                {isLoading ? (
                  <div className="w-[25px] h-[25px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ) : (
                  "Continue"
                )}
              </button>
            </div>
          </div>
        )}
        {/* ============== Continue to send otp ================ */}
        {currentView === "send-otp-to-number" && (
          <div className="flex flex-col items-end pb-20 text-base text-neutral-600 max-md:mt-10 max-md:max-w-full">
            <div className="mx-auto mt-28 tracking-tight leading-5 text-center w-[390px] max-md:mt-10 max-md:max-w-full">
              An OTP (One-Time Passcode) will be sent to the registered phone
              number ending in {maskPhoneNumber(user.phone_number)}
              <br />
              Would you like to continue?
            </div>
            <div className="flex gap-4 mx-auto mt-6 font-semibold text-white max-md:flex-wrap">
              <div
                className="justify-center px-6 py-4 cursor-pointer whitespace-nowrap bg-sky-600 rounded-2xl max-md:px-5"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                No
              </div>
              <div
                className="flex-1 justify-center cursor-pointer py-4 pr-12 pl-11 bg-blue-900 rounded-2xl max-md:px-8 max-md:max-w-full"
                onClick={() => handleSendOtp()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="w-[25px] h-[25px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ):"Yes, Send me OTP"}
              </div>
            </div>
          </div>
        )}
        {/* ============== SendOtp ================ */}
        {currentView === "verify-otp" && (
          <div className="flex flex-col items-center px-8 pb-7 max-md:px-5 max-md:mt-8 max-md:max-w-full">
            <div className="mt-12 text-2xl font-bold text-blue-900 max-md:mt-10">
              Verification
            </div>
            <div className="mt-4 text-base text-black text-opacity-80 w-[350px]">
              Enter OTP (One-Time Passcode) we've sent to number ending in{" "}
              <span className="font-bold"> {maskPhoneNumber(user.phone_number)}</span>
            </div>
            <small className="text-red-600 my-2">{errorOtp}</small>
            <div className="flex gap-4 pr-3 mt-4 max-w-full w-[362px]">
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpOne(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpTwo(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpThree(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpFour(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpFive(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpSix(e.target.value)} />
            </div>
            <div className="mt-6 text-base text-black text-opacity-80">
              Did not received the code? <button className={`text-[#1c3775] font-bold ${
                countdown > 0
                 ? "opacity-50" : ""
              }`} disabled={countdown > 0} onClick={() => handleResendOtp()}>Resend 
                {countdown > 0 && (
                  <span> ({countdown}s)</span>
                )}
              </button>
            </div>
            <div className="flex gap-4 mt-6 max-w-full text-base font-semibold text-white whitespace-nowrap w-[362px]">
              <div
                className="justify-center px-6 py-4 bg-sky-600 cursor-pointer rounded-2xl max-md:px-5"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </div>
              <div
                className="flex-1 justify-center text-center cursor-pointer items-center px-6 py-4 bg-blue-900 rounded-2xl max-md:px-5"
                onClick={() => handleVerifyOtpPhone()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="w-[25px] h-[25px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ):"Apply"}
              </div>
            </div>
          </div>
        )}
        {/* ============== New Number ================ */}
        {currentView === "input-new-number" && (
          <div className="flex flex-col items-center px-8 pb-20 text-base leading-4 text-neutral-600 max-md:px-5 max-md:mt-8 max-md:max-w-full">
            <div className="mt-28 tracking-tight max-md:mt-10">
              Enter New Phone Number
            </div>
            <PhoneNumber onPhoneNumberChange={handlePhoneNumberChange}/>
            <div className="flex gap-4 mt-4 max-w-full font-semibold text-white whitespace-nowrap w-[346px]">
              <div
                className="justify-center cursor-pointer px-6 py-4 bg-sky-600 rounded-2xl max-md:px-5"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </div>
              <div
                className="flex-1  cursor-pointer justify-center items-center px-6 py-4 bg-blue-900 rounded-2xl max-md:px-5"
                onClick={() => handleSendOtpNewPhone()}
              >
                {isLoading ? (
                  <div className="w-[25px] h-[25px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ) : (
                  "Continue"
                )}
              </div>
            </div>
          </div>
        )}
        {/* ============== Apply new otp ================ */}
        {currentView === "verify-new-otp" && (
          <div className="flex flex-col items-center px-8 pb-7 max-md:px-5 max-md:mt-8 max-md:max-w-full">
            <div className="mt-12 text-2xl font-bold text-blue-900 max-md:mt-10">
              Verification
            </div>
            <div className="mt-4 text-base text-black text-opacity-80 w-[350px]">
              Enter OTP (One-Time Passcode) we've sent to number ending in{" "}
              <span className="font-bold"> {maskPhoneNumber(registrationPhoneNumber)}</span>
            </div>
            <small className="text-red-600 my-2">{errorOtp}</small>
            <div className="flex gap-4 pr-3 mt-4 max-w-full w-[362px]">
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpOneNP(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpTwoNP(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpThreeNP(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpFourNP(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpFiveNP(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpSixNP(e.target.value)} />
            </div>
            <div className="mt-6 text-base text-black text-opacity-80">
              Did not received the code? <button className={`text-[#1c3775] font-bold ${
                countdown > 0
                 ? "opacity-50" : ""
              }`} disabled={countdown > 0} onClick={() => handleResendOtpNewPhone()}>Resend 
                {countdown > 0 && (
                  <span> ({countdown}s)</span>
                )}
              </button>
            </div>
            <div className="flex gap-4 mt-6 max-w-full text-base font-semibold text-white whitespace-nowrap w-[362px]">
              <div
                className="justify-center  cursor-pointer px-6 py-4 bg-sky-600 rounded-2xl max-md:px-5"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </div>
              <div
                className="flex-1  cursor-pointer text-center justify-center items-center px-6 py-4 bg-blue-900 rounded-2xl max-md:px-5"
                onClick={() => handleVerifyOtpNewPhone()}
              >
                {isLoading ? (
                  <div className="w-[25px] h-[25px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ) : (
                  "Apply"
                )}
              </div>
            </div>
          </div>
        )}
        {/* ============== success ================ */}
        {currentView === "success" && (
          <div className="flex flex-col items-center px-8 pb-20 text-base max-md:px-5 max-md:mt-8 max-md:max-w-full">
            <div className="flex justify-center items-center px-16 mt-32 max-w-full text-lg font-bold tracking-tight leading-5 text-blue-900 w-[733px] max-md:px-5 max-md:mt-10">
              <div className="flex gap-4">
                <img
                  alt="img"
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/acec081d89f740a85c7d3323f4e60b6e3ed1913bb0063e44989cb12cc6bbc4e5?"
                  className="shrink-0 my-auto aspect-square w-[18px]"
                />
                <div>Phone Number Updated</div>
              </div>
            </div>
            <div className="mt-2 text-base leading-5 text-center text-neutral-600 max-md:max-w-full">
              You successfully changed your Phone Number
            </div>
            <div className="shrink-0 mt-2 max-w-full h-px border border-solid bg-neutral-100 border-neutral-100 w-[435px]" />
            <div
              className="justify-center  cursor-pointer px-6 py-3 mt-2 font-semibold text-white whitespace-nowrap bg-blue-900 rounded-2xl max-md:px-5"
              onClick={() => navigate("/dashboard/account-settings")}
            >
              Continue
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChangeNumber;
