const items = [
  {
    title: "How good is my privacy if I sign up",
    content: (
      <div>
        Your privacy means everything to us, being a company, Registered in
        Australia we respect other's privacy more so than in other countries,
        this website is under Australian jurisdiction and we take our liberty
        very seriously,
        <div>
          The fines and jail sentence's for companies and company directors are
          horrendous, to check your rights click on "PRIVACY"
          https://www.oaic.gov.au/privacy/the-privacy-act “We do not hand out
          your information whatsoever”. Information you add to your profile is
          all that anyone will see.
        </div>
      </div>
    ),
  },
  {
    title: "Do I Qualify To Register",
    content: `As long as you feel the need to prove “You Are Who You Say You Are” and want clients to trust you.
      you need to do this so that any prospective new employers online see’s that.
      
      There are a lot of countries that a prospective employer will not employ a contractor from due to well known scams, the contractor will obtain a deposit from the client and then disappear.
      
      With the FullIDCheck badge, you will be more trusted as the client will know you can not run, this means that the client will check your profile first and see good things about you,`,
  },
  {
    title: "How am I being verified when Registering",
    content: `Information is gathered, including, but not limited to, photos of the Drivers License, Passport, Country ID and an amenity bill with current address. You will need to supply these documents to complete facial recognition.If there is a problem with you registering, then the next step is for you to have an interview with a Manager from your country of origin; we have allowed a manager for each country that can speak the same language and knows what documents for you to produce at the time of the interview, you can still be refused if there is any suspicion of paperwork being tampered with. Please be aware that the manager will video the interview for security purposes.`,
  },
  {
    title: "What does it cost to register",
    content: `The cost to register is $195.00 for a one-off lifetime registration this also covers membership for the first year.There is an annual membership fee of $135.00 USDIf you fail to pay your annual membership fees yearly and decide to come back after a couple of years you will not be back charged for the time you were not a paid-up member.As a paid-up member you have access to clients and contractsif not a paid-up member then the links on your profile will be dead and no client will be able to contact you, the client can still see your profile but they take the risk of getting scammed and you risk getting a “Warning” over top of your profile if a complaint is lodged.`,
  },
  {
    title: "Who Needs To Register ",
    content: `At this time only developers will register, we are working on a bigger platform toregister everyone who works online like the following :Software Developers:Freelance writer:Blogger:Online Tudor:Search Engine Evaluator:Graphic Designer:Transcriptionist:Social Media Manager:Technical writer:Freelance Marketing Consultant Grant Writer:Translator/interpreter:`,
  },
  {
    title: "A verified member commits fraud",
    content: `The developer intentionally scams clients or other members then we will immediately freeze their profile with a warning,“Under Investigation” or “Warning”As the member is registered for life, their information will always be there to be seen, and if the member requests to the admin to have the ban removed, it will be up to the admin’s discretion.If any government issues devreg.org a warrant to obtain a developer’s information as a result of a scam, or fraudulent act, we will contact that developer before releasing the information. The developer has 7 days in which to respond to us. After 7 days, we will hand over any/all requested information to the authorities.Please note we will not hand over information on any registered member if any government wants listed information on coins or developersThe member must have committed fraud and a client reports the fraud to the Devreg Admin, Otherwise,“Governments leave our members alone”.`,
  },
  {
    title: "Member tries to re-register with a different name",
    content: "lorem ipsum",
  },
  {
    title: "How the registered badge works for a client ",
    content: "lorem ipsum",
  },
  {
    title: "How the coin register works",
    content: "lorem ipsum",
  },
  {
    title: "Cryptocurrency Developers and use of their badge",
    content: "lorem ipsum",
  },
  {
    title: "I registered a coin and now it is dead",
    content: "lorem ipsum",
  },
  {
    title: "I want to resign from my coin",
    content: "lorem ipsum",
  },
  {
    title: 'I Am A Student, "What About Me"',
    content: "lorem ipsum",
  },
];

export { items };
