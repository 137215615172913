import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CiCircleCheck } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { setIsLoggedIn, setIsLoading } from "../../../../actions/index.js";
import { 
  changePassword,
  logout,
} from "../../../../api/apiCall.js";

function ChangePassword() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [reloginAlert, setReloginAlert] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      setPassword(value);
      setIsLengthValid(value.length >= 8);
      setHasUpperCase(/[A-Z]/.test(value));
      setHasLowerCase(/[a-z]/.test(value));
      setHasNumber(/\d/.test(value));
      setHasSpecialChar(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value));
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
      setPasswordsMatch(value === password);
    }
  };
  const confirmChangePassword = async () => {
    dispatch(setIsLoading(true));
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          password: oldPassword,
          new_password: password,
          new_password_confirmation: confirmPassword
        }
      }
      const response = await changePassword(data);
      if (response.status === 200) {
        setReloginAlert(true);
      }
    } 
    catch (error) {
      console.error("Error marking a message:", error);
    }
    finally{
      dispatch(setIsLoading(false));
    }
  }

  const confirmLogout = () => {
    localStorage.clear();
    dispatch(setIsLoggedIn(false));
    navigate("/login")
  }


  return (
    <div className=" min-h-[89vh]">
      <div className="w-full px-5">
        <div className="flex flex-col px-8 max-md:px-5 max-md:mt-8 max-md:max-w-full">
          <div className="text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
            Security Settings &gt; Change Password
          </div>
          <div className="mt-6 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
            Password
          </div>
          <div className="mt-6 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-2/5 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow text-base leading-5 placeholder-text-stone-300 max-md:mt-6">
                  <input
                    type="password"
                    className="justify-center items-start px-4 py-3 rounded-xl border border-solid border-neutral-300 max-md:pr-5"
                    placeholder="Old Password"
                    onChange={(e) => setOldPassword(e.target.value)}
                  />

                  <input
                    className="justify-center items-start px-4 py-3 mt-4 rounded-xl border border-solid border-neutral-300 max-md:pr-5"
                    placeholder="New Password"
                    value={password}
                    type="password"
                    name="password"
                    onChange={handleChange}
                  />

                  <input
                    className={`
                   ${passwordsMatch ? "border-neutral-300" : "border-red-600"} 
                   className="justify-center items-start px-4 py-3 mt-4 rounded-xl border border-solid max-md:pr-5`}
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={handleChange}
                    // disabled={
                    //   !isLengthValid ||
                    //   !hasUpperCase ||
                    //   !hasLowerCase ||
                    //   !hasNumber ||
                    //   !hasSpecialChar
                    // }
                    type="password"
                    name="confirmPassword"
                  />

                  <div className="cursor-pointer flex gap-4 mt-4 text-base font-semibold text-white">
                    <div
                      onClick={() => navigate("/dashboard/account-settings")}
                      className="justify-center px-6 py-4 whitespace-nowrap bg-sky-600 rounded-2xl max-md:px-5"
                    >
                      Cancel
                    </div>
                    <div
                      className={`flex-1 justify-center px-6 py-4 bg-blue-900 text-center rounded-2xl max-md:px-5 cursor-pointer ${
                        passwordsMatch ? "" : "opacity-50 pointer-events-none"
                      }`}
                      onClick={() => confirmChangePassword()}
                      disabled={!passwordsMatch}
                    >
                      Save Password
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-3/5 max-md:ml-0 max-md:w-full">
                <div className="mt-4 w-full text-base leading-8 text-zinc-600 max-md:max-w-full">
                  Your password should:
                </div>
                <div
                  className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${
                    isLengthValid ? " text-green-700" : "  text-zinc-600"
                  } `}
                >
                  {isLengthValid ? (
                    <CiCircleCheck
                      size={21}
                      className="min-w-[20px] max-w-[20px]"
                    />
                  ) : (
                    <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px]  ml-[2px] mt-[3px]" />
                  )}
                  <div className="flex-auto">
                    Be at least 8 characters in length
                  </div>
                </div>
                <div
                  className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${
                    hasUpperCase && hasLowerCase
                      ? " text-green-700"
                      : "  text-zinc-600"
                  } `}
                >
                  {hasUpperCase && hasLowerCase ? (
                    <CiCircleCheck
                      size={21}
                      className="min-w-[20px] max-w-[20px]"
                    />
                  ) : (
                    <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px]  ml-[2px] mt-[3px]" />
                  )}
                  <div className="flex-auto ">
                    Contain both upper and lowercase alphabetic characters (e.g.
                    A-Z, a-z)
                  </div>
                </div>
                <div
                  className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${
                    hasNumber ? " text-green-700" : "  text-zinc-600"
                  } `}
                >
                  {hasNumber ? (
                    <CiCircleCheck
                      size={21}
                      className="min-w-[20px] max-w-[20px]"
                    />
                  ) : (
                    <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px]  ml-[2px] mt-[3px]" />
                  )}
                  <div className="flex-auto max-md:max-w-full">
                    Have at least one numerical character (e.g. 0-9)
                  </div>
                </div>
                <div
                  className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${
                    hasSpecialChar ? " text-green-700" : "  text-zinc-600"
                  } `}
                >
                  {hasSpecialChar ? (
                    <CiCircleCheck
                      size={21}
                      className="min-w-[20px] max-w-[20px]"
                    />
                  ) : (
                    <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px]  ml-[2px] mt-[3px]" />
                  )}
                  <div className="flex-auto ">
                    Have at least one special character (e.g. ~!@#$%^&*()_-+=)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {reloginAlert && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-1/3">
            <h2 className="text-lg font-semibold mb-4 text-center">
              Re-login Required
            </h2>
            <p className="text-gray-700 text-sm text-center mb-6">
              Re-login required to apply changes. Please log out and log back in.
            </p>
            <div className="flex justify-center">
              <button
                onClick={confirmLogout}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Confirm Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChangePassword;
