import React, { useState } from "react";
import { FaRegEnvelope } from "react-icons/fa";
import BacktoLogin from "../components/BackToLogin";
import TwoStepVerification from "./TwoStepVerification";
import { forgetPasswordSendOtpEmail } from "../../../api/apiCall.js";

function ForgotPassMain() {
  const [showVerifyCode, setShowVerifyCode] = useState(false);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(null);
  const [countdown, setCountdown] = useState(0);

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let validateResult = !emailRegex.test(email);
    setIsValidEmail(validateResult);
    return validateResult;
  };

  const handleSendOtpEmail = async () => {
    try {
      if (validateEmail()) {
        return;
      }

      let data = {
        payloads: {
          email: email,
        },
      };
      const response = await forgetPasswordSendOtpEmail(data);
      if (response.status === 200) {
        setShowVerifyCode(true);
      }
    } catch (error) {
      console.log("Error sending OTP: ", error);
    }
  };

  const handleResendOtpEmail = async () => {
    try {
      if (validateEmail()) {
        return;
      }

      let data = {
        payloads: {
          email: email,
        },
      };
      const response = await forgetPasswordSendOtpEmail(data);
      if (response.status === 200) {
        startCountdown();
        setShowVerifyCode(true);
      }
    } catch (error) {
      console.log("Error resending OTP: ", error);
    }
  };

  const startCountdown = () => {
    setCountdown(30);
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendOtpEmail();
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-devRegDarkBlue">
      {!showVerifyCode && (
        <>
          <div className="flex flex-col items-center p-20 text-base bg-devRegDarkBlue flex-grow">
            <div className="flex flex-col p-6 mt-6 max-w-full font-semibold bg-white rounded-3xl shadow-sm w-[400px] max-md:px-8">
              <div className="flex items-center justify-between w-full mb-4">
                <div className="flex justify-start items-center gap-1 cursor-pointer text-teal-400 text-base font-medium">
                  <BacktoLogin />
                </div>
                <img src="/logoFooter.png" alt="Devreg Logo" className="h-8" />
              </div>
              <hr className="w-full border-t border-gray-200" />
              <div className="mt-8 mr-auto text-xl text-center text-neutral-800 max-sm:mr-auto max-md:text-xl">
                Forgot Password
              </div>
              <div className="mt-2 text-zinc-700 text-opacity-80 max-sm:mx-auto max-sm:text-sm max-md:text-md text-sm">
                Enter your email and we’ll send you instructions to reset your
                password
              </div>
              <div className="relative mt-6">
                <FaRegEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  value={email}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  className="w-full pl-10 pr-4 py-2.5 rounded-xl border border-neutral-300 placeholder-text-stone-300"
                />
              </div>
              {isValidEmail && (
                <div className="text-red-600 text-sm mt-1">
                  Please enter a valid email address.
                </div>
              )}

              <div
                className="cursor-pointer justify-center items-center px-9 py-3 mt-4 text-base text-white bg-blue-900 rounded-3xl max-md:px-5 text-center"
                onClick={handleSendOtpEmail}
              >
                Send to Email
              </div>
              <div className="cursor-pointer self-center mt-4 text-base font-bold text-center">
                <span className="text-neutral-400 font-normal">
                  Didn’t get the email?
                </span>
                <button
                  className={`ml-1 text-devRegBlue ${
                    countdown > 0 ? "opacity-50" : ""
                  }`}
                  disabled={countdown > 0}
                  onClick={handleResendOtpEmail}
                >Resend
                  {countdown > 0 && <span> ({countdown}s)</span>}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {showVerifyCode && (
        <>
          <TwoStepVerification email={email} />
        </>
      )}
    </div>
  );
}

export default ForgotPassMain;
