import React from "react";
import { useNavigate } from "react-router-dom";

function HaveQuestions() {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center min-[640px]:px-10 max-w-main m-auto mt-20">
      <div
        className={`bg-devRegDarkBlue justify-between py-16 px-14 w-full max-sm:rounded-none rounded-2xl max-md:px-5 max-md:mt-10 max-md:max-w-full`}
      >
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[79%] max-md:ml-0 max-md:w-full">
            <div className="text-3xl text-white max-md:text-[25px] font-semibold max-sm:text-center tracking-tight leading-8  max-md:mt-5 max-md:mb-5 max-md:max-w-full">
              Have Questions?
            </div>
            <div className="text-2xl text-white max-md:text-[20px] font-semibold max-sm:text-center tracking-tight leading-8  max-md:mt-1 max-md:mb-5 max-md:max-w-full">
              Reach out to us and ask you questions away
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[21%] max-md:ml-0 max-md:w-full max-lg:w-[30%]">
            <div
              className="bg-[#e0e7ff]
       justify-center self-stretch max-sm:m-auto px-6 py-2 my-auto text-xl font-semibold max-sm:text-sm16 text-devRegDarkBlue rounded-[93.75px] max-md:px-5 text-center cursor-pointer"
              onClick={() => {
                navigate("/register");
                window.scrollTo(0, 0);
              }}
            >
              Get Help
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HaveQuestions;