import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

function FilterBar({ filterTunnel }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Most Recent");

  const options = ["Most Recent", "Oldest", "Highest Rated", "Lowest Rated"];

  const navigate = useNavigate();

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    if(option === selectedOption) return;
    setSelectedOption(option);
    filterTunnel({
      type: "filter",
      data: {
        value: option,
      },
    })
    setIsOpen(false);
  };

  return (
    <div className="flex flex-wrap gap-10 justify-between items-start pt-6 mt-6 w-full max-md:max-w-full max-[425px]:w-full max-[425px]:justify-end">
      <div className="relative max-[425px]:w-full">
        <div
          className="flex py-3 rounded-lg border border-gray-400 border-solid min-w-[240px] cursor-pointer max-[425px]:w-full"
          onClick={toggleDropdown}
        >
          <div className="gap-2 self-stretch px-3 my-auto text-sm text-gray-400 w-[222px] max-[425px]:w-full">
            {selectedOption}
          </div>
          <div className="flex gap-2 justify-center items-center px-3 h-full w-[38px]">
            <FaAngleDown className="object-contain self-stretch my-auto w-3.5 text-gray-400 aspect-square" />
          </div>
        </div>

        {isOpen && (
          <div className="absolute mt-1 py-2 bg-white border border-gray-300 rounded-lg shadow-lg min-w-[240px] z-10 max-[425px]:w-full">
            {options.map((option) => (
              <div
                key={option}
                className="px-4 py-2 hover:bg-gray-100 text-sm text-gray-700 cursor-pointer"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
      <button 
        className="flex gap-1 border-none justify-center items-center py-2.5 pr-5 pl-4 text-base font-medium text-gray-50 bg-blue-900 rounded-lg"
        onClick={() => navigate("/dashboard/forum/new-post")}  
      >
        <FaPlus className="object-contain shrink-0 self-stretch my-auto aspect-square w-[15px]" />
        <div className="self-stretch my-auto">New Post</div>
      </button>
    </div>
  );
}

export default FilterBar;
