import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
function UploadSuccess() {

    const navigate = useNavigate();

    const handleGotoLoginPage = () => {
        localStorage.clear()
        navigate("/login");
    };

    return (
        <div className="bg-devRegDarkBlue py-14 h-screen">
            <div className="h-[30vh] px-10">
                <div className="flex justify-center items-center flex-col mx-auto mt-20">
                    <div className="flex justify-center items-center flex-col max-w-[730px] border border-gray-200 p-6 rounded-lg bg-white">
                        <div className="flex items-center justify-center gap-3 px-4">
                            <FaRegCheckCircle className="text-[45px] text-devRegGreen" />
                            <div className="flex-1 text-[30px] font-extrabold text-[#1c3775]">
                                Documents Requirements Uploaded
                            </div>
                        </div>
                        <div className="mt-4 text-center">
                            You successfully uploaded your document requirements.
                        </div>
                        <div className="w-full m-auto">
                            <button
                                onClick={handleGotoLoginPage}
                                className="mt-7 w-full m-auto bg-[#1c3775] text-white px-7 py-2 rounded-full font-semibold text-lg "
                            >
                                Go to Login Page
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UploadSuccess;
