import { React, useState, useEffect } from "react";
import { CiUser } from "react-icons/ci";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

const users = [
  {
    name: "Andrey Kapustin",
    role: "Sr. Country Manager",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/e47fe0e56249716e710e1530f6befaf80684025d2e36b3db20a22870eb4b58b2?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c",
    flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/6110b07cde33ea1280d9580461a2ee41f5102f9e21493d84b41868120c413753?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c",
  },
  {
    name: "John Doe",
    role: "Country Manager",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/43e4ff29ff083161ddc9b8f37c35fa6306faa7f6d7f9ac10bd56ffe627a87114?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c",
    flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/955a975261722b7aa1dc875f52ae6bf80a7d19823ec7112c61c6293f336896fc?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c",
  },
  {
    name: "John Doe",
    role: "Country Manager",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/43e4ff29ff083161ddc9b8f37c35fa6306faa7f6d7f9ac10bd56ffe627a87114?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c",
    flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/955a975261722b7aa1dc875f52ae6bf80a7d19823ec7112c61c6293f336896fc?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c",
  },
  {
    name: "John Doe",
    role: "Country Manager",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/43e4ff29ff083161ddc9b8f37c35fa6306faa7f6d7f9ac10bd56ffe627a87114?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c",
    flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/955a975261722b7aa1dc875f52ae6bf80a7d19823ec7112c61c6293f336896fc?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c",
  },
  {
    name: "John Doe",
    role: "Country Manager",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/43e4ff29ff083161ddc9b8f37c35fa6306faa7f6d7f9ac10bd56ffe627a87114?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c",
    flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/955a975261722b7aa1dc875f52ae6bf80a7d19823ec7112c61c6293f336896fc?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c",
  },
  {
    name: "John Doe",
    role: "Country Manager",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/43e4ff29ff083161ddc9b8f37c35fa6306faa7f6d7f9ac10bd56ffe627a87114?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c",
    flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/955a975261722b7aa1dc875f52ae6bf80a7d19823ec7112c61c6293f336896fc?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c",
  },
  {
    name: "John Doe",
    role: "Country Manager",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/43e4ff29ff083161ddc9b8f37c35fa6306faa7f6d7f9ac10bd56ffe627a87114?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c",
    flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/955a975261722b7aa1dc875f52ae6bf80a7d19823ec7112c61c6293f336896fc?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c",
  },
  {
    name: "John Doe",
    role: "Country Manager",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/43e4ff29ff083161ddc9b8f37c35fa6306faa7f6d7f9ac10bd56ffe627a87114?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c",
    flag: "https://cdn.builder.io/api/v1/image/assets/TEMP/955a975261722b7aa1dc875f52ae6bf80a7d19823ec7112c61c6293f336896fc?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c",
  },
];

function MeetTheStaffs() {
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);
  const [searchQuery, setSearchQuery] = useState("");

  const itemsPerPage = isMobile ? 1 : 8; // Show only 1 item per page on mobile

  // Filter users based on search query
  const filteredUsers = users.filter(user => 
    user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.role.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate the indexes for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    // Update screen size on resize
    const handleResize = () => setIsMobile(window.innerWidth <= 425);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getPaginationNumbers = () => {
    if (!isMobile) {
      // Show all page numbers for larger screens
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    }

    // For mobile screens, show up to 3 page numbers
    const paginationNumbers = [];
    const maxVisible = 3;
    
    if (totalPages <= maxVisible) {
      // Show all pages if less than or equal to maxVisible
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    }

    const start = Math.max(1, currentPage - 1);
    const end = Math.min(totalPages, currentPage + 1);

    for (let i = start; i <= end; i++) {
      paginationNumbers.push(i);
    }

    if (currentPage > 1) {
      paginationNumbers.unshift('...');
    }
    if (currentPage < totalPages) {
      paginationNumbers.push('...');
    }

    return paginationNumbers;
  };

  return (
    <div className={`flex flex-col pt-10 pb-20 items-center max-md:px-3 ${isMobile ? "px-4" : "px-10"}`}>
      <div className={`flex flex-col ${isMobile ? "max-w-full px-0" : "max-w-main px-10"} w-full`}>
        <div className="text-3xl font-bold text-blue-900 text-start max-[425px]:text-center max-md:mb-6">
          Meet The Country Managers and Senior Country Managers
        </div>
        <div className={`flex flex-wrap gap-4 items-center mt-4 w-full ${isMobile ? "justify-center flex-col" : ""}`}>
          {/* Input field for search */}
          <div className={`relative flex items-center text-base border border-gray-400 rounded-lg text-gray-400 bg-transparent ${isMobile ? "w-full max-w-full" : ""} max-md:w-full`}>
            <div className="absolute left-2">
              <CiUser size={25} />
            </div>
            <input
              type="text"
              placeholder="Search Name, Position, etc."
              className={`pl-10 pr-8 py-4 text-base text-gray-400 outline-none border-none bg-transparent w-full max-md:w-full`}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          {/* Dropdown for selecting country */}
          <div className={`flex items-center my-auto rounded-lg border border-gray-400 ${isMobile ? "w-full max-w-full" : "min-w-[300px]"} max-md:w-full`}>
            <select className={`flex-1 px-3 py-4 text-base border-none text-gray-400 outline-none bg-transparent ${isMobile ? "w-full max-w-full" : ""}`}>
              <option value="Australia">Australia</option>
              <option value="USA">USA</option>
              <option value="UK">UK</option>
              <option value="Canada">Canada</option>
              <option value="India">India</option>
            </select>
          </div>
        </div>
        <div className="flex flex-col justify-center mt-10">
          <div className={`flex flex-wrap gap-12 justify-center w-full ${isMobile ? "items-center" : ""}`}>
            {currentUsers.map((user, index) => (
              <div
                key={index}
                className={`flex flex-col items-center p-4 ${isMobile ? "w-[260px]" : "min-w-[260px] w-[280px]"}`}
              >
                <img
                  loading="lazy"
                  srcSet={`${user.image}&width=100 100w, ${user.image}&width=200 200w, ${user.image}&width=400 400w, ${user.image}&width=800 800w`}
                  className="object-contain max-w-full aspect-square w-[150px]"
                  alt={`${user.name}'s photo`}
                />
                <div className="flex flex-col mt-4 w-full text-center">
                  <div className="text-2xl font-bold tracking-tight text-blue-900">
                    {user.name}
                  </div>
                  <div className="flex gap-2 items-center justify-center mt-1 text-base text-devRegDarkBlue">
                    <img
                      loading="lazy"
                      src={user.flag}
                      className="object-contain w-6 aspect-[1.5]"
                      alt={`${user.name}'s flag`}
                    />
                    <div>{user.role}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-10">
            <nav>
              <ul className="flex items-center gap-4">
                <li>
                  <button
                    onClick={prevPage}
                    className={`px-2 py-2 ${currentPage === 1 ? "text-gray-400" : "text-gray-700"} rounded-lg`}
                    disabled={currentPage === 1}
                  >
                    <GoChevronLeft size={35}/>
                  </button>
                </li>
                {getPaginationNumbers().map((number, index) => (
                  <li key={index}>
                    {number === '...' ? (
                      <span className="px-4 py-2 text-gray-500">...</span>
                    ) : (
                      <button
                        onClick={() => paginate(number)}
                        className={`px-4 py-2 ${currentPage === number ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"} rounded-lg`}
                      >
                        {number}
                      </button>
                    )}
                  </li>
                ))}
                <li>
                  <button
                    onClick={nextPage}
                    className={`px-2 py-2 ${currentPage === totalPages ? "text-gray-400" : "text-gray-700"} rounded-lg`}
                    disabled={currentPage === totalPages}
                  >
                    <GoChevronRight size={35}/>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetTheStaffs;