import React from "react";

function Header() {
  return (
    <div className="max-w-main px-10 m-auto max-md:px-3">
      <button
        disabled={true}
        className=" py-1.5 px-6 mt-16 text-base font-medium text-devRegBlue uppercase bg-cyan-50 rounded-full max-md:mt-10 max-md:ml-2.5 max-sm:text-sm16"
      >
        ABOUT US
      </button>
      <div className="flex justify-between max-md:flex-col">
        <div className="pr-4 max-md:px-3 w-2/5 text-[30px] max-md:w-full font-bold leading-9 max-md:leading-7 max-md:text-sm20 text-devRegDarkBlue mt-4">
          DevReg®: Your Global Software Developer Trust Platform​
        </div>
        <p className="w-1/2 max-md:w-full px-3 text-sm15 mt-2 text-devRegDarkBlue">
          DevReg® serves as a worldwide identity hub for software developers.
          Through advanced data science and machine learning, we curate trusted
          developer datasets, ensuring only authentic identities gain access.
        </p>
      </div>
    </div>
  );
}

export default Header;