import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsLoggedIn, setIsLoading } from "../../../../actions/index.js";
import { 
  checkPassword,
  sendOtpEmail,
  verifyOtpEmail,
  sendOtpNewEmail,
  verifyOtpNewEmail,
  logout,
} from "../../../../api/apiCall.js";

function ChangeEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const [errorMessage, setErrorMessage] = useState(null);
  const [errorOtp, setErrorOtp] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [emailToken, setEmailToken] = useState("");
  
  const [currentView, setCurrentView] = useState("verify-pass-email");
  // const [currentView, setCurrentView] = useState("input-new-email");
  const [password, setPassword] = useState("");
  const [resendOtpLink, setResendOtpLink] = useState("");
  const [OtpOne, setOtpOne] = useState(null);
  const [OtpTwo, setOtpTwo] = useState(null);
  const [OtpThree, setOtpThree] = useState(null);
  const [OtpFour, setOtpFour] = useState(null);
  const [OtpFive, setOtpFive] = useState(null);
  const [OtpSix, setOtpSix] = useState(null);
  const [countdown, setCountdown] = useState(0);
  const [newEmail, setNewEmail] = useState("");
  const [OtpOneNE, setOtpOneNE] = useState(null);
  const [OtpTwoNE, setOtpTwoNE] = useState(null);
  const [OtpThreeNE, setOtpThreeNE] = useState(null);
  const [OtpFourNE, setOtpFourNE] = useState(null);
  const [OtpFiveNE, setOtpFiveNE] = useState(null);
  const [OtpSixNE, setOtpSixNE] = useState(null);


  const handleCheckPassword = async () => {
    setIsLoading(true);
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          password: password
        }
      }
      const response = await checkPassword(data);
      if (response.status === 200) {
        setErrorMessage(null);
        setCurrentView("send-otp-to-email")
      }
    }
    catch(error){
      setErrorMessage(error.response.data.message);
      console.error("Error checking password: ", error);
    }
    finally{
      setIsLoading(false);
    }
  }

  const hideEmail = (email) => {
    const [localPart, domainPart] = email.split('@');
    const lettersToHide = Math.max(localPart.length - 3, 0);
    const hiddenLocalPart = localPart[0] + localPart[1] + '*'.repeat(lettersToHide) + localPart.slice(-1);
    return `${hiddenLocalPart}@${domainPart}`;
  }

  const handleSendOtpEmail = async () => {
    setIsLoading(true);
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          email: user.email,
        }
      }
      const response = await sendOtpEmail(data);
      if (response.status === 200) {
        setResendOtpLink(response.resend_otp_link);
        setCurrentView("verify-otp-email");
      }
    }
    catch(error){
      console.error("Error sending otp: ", error);
    }
    finally{
      setIsLoading(false);
    }
  }

  const handleResendOtpEmail = async () => {
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          email: user.email,
          phone_number: user.phone_number
        }
      }
      const response = await sendOtpEmail(data);
      if (response.status === 200) {
        setResendOtpLink(response.resend_otp_link);
        startCountdown()
        setCurrentView("verify-otp-email");
      }
    }
    catch(error){
      console.error("Error resending otp: ", error);
    }
  }

  const startCountdown = () => {
    setCountdown(30);
    const interval = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const handleVerifyOtpEmail = async () => {
    setIsLoading(true);
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          otp: `${OtpOne}${OtpTwo}${OtpThree}${OtpFour}${OtpFive}${OtpSix}`,
          email: user.email,
          is_change_email: 1,
        }
      }
      console.log(data.payloads);
      const response = await verifyOtpEmail(data);
      if (response.status === 200) {
        setEmailToken(response.data.token);
        setCurrentView("input-new-email");
        setErrorOtp(null);
      }
    }
    catch(error){
      console.error("Error verifying OTP: ", error);
      setErrorOtp(error.response.data.message);
    }
    finally{
      setIsLoading(false);
    }
  }
  
  const handleSendOtpNewEmail = async () => {
    setIsLoading(true);
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          email: newEmail,
          token: emailToken,
        }
      }
      const response = await sendOtpNewEmail(data);
      if (response.status === 200) {
        // setResendOtpLink(response.resend_otp_link);
        // setCurrentView("verify-new-otp");
        setErrorOtp(null);
        user.email = newEmail;
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('account_updated', true);
        setCurrentView("success");
      }
    }
    catch(error){
      console.error("Error sending otp to new email: ", error);
    }
    finally{
      setIsLoading(false);
    }
  }
  
  const handleResendOtpNewEmail = async () => {
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          new_email: newEmail,
          new_email_confirmation: newEmail
        }
      }
      const response = await sendOtpNewEmail(data);
      if (response.status === 200) {
        setResendOtpLink(response.resend_otp_link);
        startCountdown();
        setCurrentView("verify-new-otp");
      }
    }
    catch(error){
      console.error("Error sending otp to new email: ", error);
    }
  }

  const handleVerifyOtpNewEmail = async () => {
    setIsLoading(true);
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          otp: `${OtpOneNE}${OtpTwoNE}${OtpThreeNE}${OtpFourNE}${OtpFiveNE}${OtpSixNE}`,
          new_email: newEmail
        }
      }
      console.log(data.payloads);
      const response = await verifyOtpNewEmail(data);
      if (response.status === 200) {
        setErrorOtp(null);
        localStorage.setItem('account_updated', true);
        setCurrentView("success");
      }
    }
    catch(error){
      console.error("Error verifying OTP: ", error);
      setErrorOtp(error.response.data.message);
    }
    finally{
      setIsLoading(false);
    }
  }

  const handleLogout = async () => {
    localStorage.clear();
    dispatch(setIsLoggedIn(false));
    navigate("/login")
  }


  return (
    <div className=" min-h-[89vh]">
      <div className="w-full px-5">
        <div className="self-start tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
          Security Settings &gt; Change Email
        </div>
        {/* =================== Change Email =================== */}
        {currentView === "verify-pass-email" && (
          <div className="flex flex-col items-center px-8 pb-20 text-base max-md:px-5 max-md:mt-8 max-md:max-w-full">
            <small className="text-red-600">{errorMessage}</small>
            <input
              className="justify-center items-start px-4 py-3 mt-4 max-w-full text-base leading-5 rounded-xl border border-solid placeholder-text-stone-300 border-neutral-300  w-[346px] max-md:px-5 max-md:mt-2"
              placeholder="Enter Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="flex gap-4 mt-6 max-w-full font-semibold text-white whitespace-nowrap w-[346px]">
              <div
                className="justify-center cursor-pointer px-6 py-4 bg-sky-600 rounded-2xl max-md:px-5"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </div>
              <div
                className="flex-1 cursor-pointer text-center justify-center items-center px-6 py-4 bg-blue-900 rounded-2xl max-md:px-5"
                onClick={() => handleCheckPassword()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="w-[25px] h-[25px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ):"Continue"}
              </div>
            </div>
          </div>
        )}
        {/* ============== Continue to send otp ================ */}
        {currentView === "send-otp-to-email" && (
          <div className="flex flex-col items-end pb-20 text-base text-neutral-600 max-md:mt-10 max-md:max-w-full">
            <div className="mx-auto mt-28 tracking-tight leading-5 text-center w-[390px] max-md:mt-10 max-md:max-w-full">
              An OTP (One-Time Passcode) will be sent to email address
              {hideEmail(user.email)}
              <br />
              Would you like to continue?
            </div>
            <div className="flex gap-4 mx-auto mt-6 font-semibold text-white max-md:flex-wrap">
              <div
                className="justify-center cursor-pointer px-6 py-4 whitespace-nowrap bg-sky-600 rounded-2xl max-md:px-5"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                No
              </div>
              <div
                className="flex-1 cursor-pointer justify-center py-4 pr-12 pl-11 bg-blue-900 rounded-2xl max-md:px-8 max-md:max-w-full"
                onClick={() => handleSendOtpEmail()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="w-[25px] h-[25px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ):"Yes, Send me OTP"}
              </div>
            </div>
          </div>
        )}
        {/* ============== SendOtp ================ */}
        {currentView === "verify-otp-email" && (
          <div className="flex flex-col items-center px-8 pb-7 max-md:px-5 max-md:mt-8 max-md:max-w-full">
            <div className="mt-12 text-2xl font-bold text-blue-900 max-md:mt-10">
              Verification
            </div>
            <div className="mt-4 text-base text-black text-opacity-80 w-[350px]">
              Enter OTP (One-Time Passcode) we've sent to email address{" "}
              <span className="font-bold"> {hideEmail(user.email)}</span>
            </div>
            <small className="text-red-600 my-3">{errorOtp}</small>
            <div className="flex gap-4 pr-3 mt-4 max-w-full w-[362px]">
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpOne(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpTwo(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpThree(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpFour(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpFive(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpSix(e.target.value)} />
            </div>
            <div className="mt-6 text-base text-black text-opacity-80">
              Did not received the code? <button className={`text-[#1c3775] font-bold ${
                countdown > 0
                 ? "opacity-50" : ""
              }`} disabled={countdown > 0} onClick={() => handleResendOtpEmail()}>Resend 
                {countdown > 0 && (
                  <span> ({countdown}s)</span>
                )}
              </button>
            </div>
            <div className="flex gap-4 mt-6 max-w-full text-base font-semibold text-white whitespace-nowrap w-[362px]">
              <div
                className="justify-center px-6 py-4 bg-sky-600 cursor-pointer rounded-2xl max-md:px-5"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </div>
              <div
                className="flex-1 justify-center text-center cursor-pointer items-center px-6 py-4 bg-blue-900 rounded-2xl max-md:px-5"
                onClick={() => handleVerifyOtpEmail()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="w-[25px] h-[25px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ):"Apply"}
              </div>
            </div>
          </div>
        )}
        {/* ============== New Email ================ */}
        {currentView === "input-new-email" && (
          <div className="flex flex-col items-center px-8 pb-20 text-base max-md:px-5 max-md:mt-8 max-md:max-w-full">
            <div className="mt-32 ">Enter new Email Address</div>
            <input
              className="justify-center items-start px-4 py-3 mt-4 max-w-full text-base leading-5 rounded-xl border border-solid placeholder-text-stone-300 border-neutral-300  w-[346px] max-md:px-5 max-md:mt-2"
              placeholder="Enter New Email"
              onChange={(e) => setNewEmail(e.target.value)}
            />
            <div className="flex gap-4 mt-6 max-w-full font-semibold text-white whitespace-nowrap w-[346px]">
              <div
                className="justify-center cursor-pointer px-6 py-4 bg-sky-600 rounded-2xl max-md:px-5"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </div>
              <div
                className="flex-1 cursor-pointer text-center justify-center items-center px-6 py-4 bg-blue-900 rounded-2xl max-md:px-5"
                onClick={() => handleSendOtpNewEmail()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="w-[25px] h-[25px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ):"Continue"}
              </div>
            </div>
          </div>
        )}
        {/* ============== Apply new otp email ================ */}
        {currentView === "verify-new-otp" && (
          <div className="flex flex-col items-center px-8 pb-7 max-md:px-5 max-md:mt-8 max-md:max-w-full">
            <div className="mt-12 text-2xl font-bold text-blue-900 max-md:mt-10">
              Verification
            </div>
            <div className="mt-4 text-base text-black text-opacity-80 w-[350px]">
              Enter OTP (One-Time Passcode) we've sent to email address{" "}
              <span className="font-bold"> {hideEmail(newEmail)}</span>
            </div>
            <small className="text-red-600 my-3">{errorOtp}</small>
            <div className="flex gap-4 pr-3 mt-4 max-w-full w-[362px]">
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpOneNE(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpTwoNE(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpThreeNE(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpFourNE(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpFiveNE(e.target.value)} />
              <input type="text" className="text-center shrink-0 p-2.5 border border-solid border-neutral-300 h-[58px] w-[45px]" maxLength={1} onChange={(e) => setOtpSixNE(e.target.value)} />
            </div>
            <div className="mt-6 text-base text-black text-opacity-80">
              Did not received the code? <button className={`text-[#1c3775] font-bold ${
                countdown > 0
                 ? "opacity-50" : ""
              }`} disabled={countdown > 0} onClick={() => handleResendOtpNewEmail()}>Resend 
                {countdown > 0 && (
                  <span> ({countdown}s)</span>
                )}
              </button>
            </div>
            <div className="flex gap-4 mt-6 max-w-full text-base font-semibold text-white whitespace-nowrap w-[362px]">
              <div
                className="justify-center cursor-pointer px-6 py-4 bg-sky-600 rounded-2xl max-md:px-5"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </div>
              <div
                className="flex-1 text-center cursor-pointer justify-center items-center px-6 py-4 bg-blue-900 rounded-2xl max-md:px-5"
                onClick={() => handleVerifyOtpNewEmail()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="w-[25px] h-[25px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ):"Apply"}
              </div>
            </div>
          </div>
        )}
        {/* ============== success ================ */}
        {currentView === "success" && (
          <div className="flex flex-col items-center px-8 pb-20 text-base max-md:px-5 max-md:mt-8 max-md:max-w-full">
            <div className="flex justify-center items-center px-16 mt-32 max-w-full text-lg font-bold tracking-tight leading-5 text-blue-900 w-[733px] max-md:px-5 max-md:mt-10">
              <div className="flex gap-4">
                <img
                  alt="img"
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/acec081d89f740a85c7d3323f4e60b6e3ed1913bb0063e44989cb12cc6bbc4e5?"
                  className="shrink-0 my-auto aspect-square w-[18px]"
                />
                <div>Email Address Updated</div>
              </div>
            </div>
            <div className="mt-2 text-base leading-5 text-center text-neutral-600 max-md:max-w-full">
              You successfully changed your Email Address. <br></br> Please re-login your account to continue.
            </div>
            <div className="shrink-0 mt-2 max-w-full h-px border border-solid bg-neutral-100 border-neutral-100 w-[435px]" />
            <div
              className="justify-center cursor-pointer px-6 py-3 mt-2 font-semibold text-white whitespace-nowrap bg-blue-900 rounded-2xl max-md:px-5"
              onClick={() => handleLogout()}
            >
              Continue
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChangeEmail;
