import React from "react";
import Profile from "./ProfileMain";

function PreviewProfile() {
  return (
    <>
      <Profile />
    </>
  );
}

export default PreviewProfile;
