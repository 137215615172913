import React, { useState } from "react";
import BacktoLogin from "../components/BackToLogin";
import { useDispatch } from "react-redux";
import { setIsLoggedIn, setIsLoading } from "../../../actions";
import { useNavigate } from "react-router-dom";
import { verifyOTP2FA } from "../../../api/apiCall";
import { FaPaste } from "react-icons/fa6";

function EnterRecoveryCodes() {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [code, setCode] = useState("");

    const handleVerify = async () => {
        dispatch(setIsLoading(true));
        try {
            let data = {
                id: user.id,
                token: token,
                payloads: {
                    email: user.email,
                    otp: code,
                }
            }

            const response = await verifyOTP2FA(data);
            if (response.status === 200) {
                localStorage.setItem('isLoggedIn', true);
                await dispatch(setIsLoggedIn(true));
                navigate("/dashboard/messages")
            } else {
                console.error("Failed to verify OTP: ", response.message);
            }
        } catch (error) {
            console.error("Error verifying your OTP: ", error);
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const handlePaste = async () => {
        try {
            const clipboardText = await navigator.clipboard.readText();
            setCode(clipboardText);
        } catch (error) {
            console.error("Failed to read clipboard contents: ", error);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-devRegDarkBlue">
            <div className="flex flex-col p-6 mt-6 max-w-full bg-white rounded-3xl shadow-sm w-[400px] max-md:px-4">
                <div className="flex items-center justify-between w-full mb-4">
                    <div className="flex justify-start items-center gap-1 cursor-pointer text-teal-400 text-base font-medium">
                        <BacktoLogin />
                    </div>
                    <img
                        src="/logoFooter.png"
                        alt="Devreg Logo"
                        className="h-8"
                    />
                </div>
                <hr className="w-full border-t border-gray-200" />
                <div className="mt-10 text-xl font-semibold text-neutral-800 max-sm:mr-auto text-start">
                    Recovery Code
                </div>
                <div className="mt-2 text-base text-zinc-700 text-opacity-80 max-sm:text-sm max-md:text-md">
                    Type your recovery codes below.
                </div>
                <div className="mt-6 relative">
                    <input
                        type="text"
                        className="w-full px-3 py-2 rounded-md border border-solid border-zinc-700 border-opacity-20 h-[46px] pr-[70px]"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        placeholder="Recovery Code"
                    />
                    <button
                        onClick={handlePaste}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-200 text-white p-2 rounded-md"
                    >
                        <FaPaste className="text-gray-400"/>
                    </button>
                </div>
                <div
                    onClick={handleVerify}
                    className="cursor-pointer justify-center items-center px-9 py-3 mt-4 text-base font-semibold text-white whitespace-nowrap rounded-3xl max-md:px-5 text-center bg-blue-900"
                >
                    Verify
                </div>
                <div className="cursor-pointer self-center mt-4 text-base text-center ">
                    <span className="font-normal text-sm text-zinc-700 text-opacity-80">
                        If you cannot find your codes, please contact Admin.
                    </span>
                </div>
            </div>
        </div>
    );
}

export default EnterRecoveryCodes;
