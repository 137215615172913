import React, { useState, useEffect } from "react";
import SearchBar from "../../components/SearchBar";
import BackButton from "../../components/BackButton";
import { MdBookmarkBorder, MdBookmark } from "react-icons/md";
import { AiFillLike, AiFillDislike  } from "react-icons/ai";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { FiMessageSquare } from "react-icons/fi";
import { IoSend } from "react-icons/io5";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import {
    getPost,
    addComment,
    replyComment,
    savePost,
    unsavePost,
    voteTopic,
    voteComment,
    voteReply,
    removeVote,
} from "../../../../../api/apiCall";
import Loading from "../../../../../component/Loading";

function TopViewedMain() {
    const token = localStorage.getItem("token");
    const { id } = useParams();

    const navigate = useNavigate();
    
    const [isFething, setIsFetching] = useState(false);
    const [showCommentInput, setShowCommentInput] = useState(false);
    const [commentText, setCommentText] = useState("");
    const [replyText, setReplyText] = useState({});
    const [showReplyInput, setShowReplyInput] = useState({});
    const [post, setPost] = useState({});

    const posts = [
        {
            id: 1,
            title: "How to Effectively get a client?",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non sapien id purus accumsan interdum vitae at felis. In interdum ex augue, sit amet vulputate quam congue vitae. Proin quis ante ac elit dapibus varius vitae id libero. Suspendisse ac maximus ipsum. Fusce id ligula urna. Praesent quam odio, dapibus non tincidunt in, tempus non eros.",
            tags: ["Dev", "Client", "Professional Work"],
            user: {
                name: "Ragnar Karlsefni",
                image: "https://cdn.builder.io/api/v1/image/assets/TEMP/305b2236f59dca300d60755c909d7ae0ca1c3eb09bd70877bef8ade9d3cc53c8?width=100"
            },
            timestamp: "1h ago",
            comments: [
                {
                    id: 1,
                    user: {
                        name: "Canute James",
                        image: "https://cdn.builder.io/api/v1/image/assets/TEMP/305b2236f59dca300d60755c909d7ae0ca1c3eb09bd70877bef8ade9d3cc53c8?width=100"
                    },
                    timestamp: "1h ago",
                    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non sapien id purus accumsan interdum vitae at felis. In interdum ex augue, sit amet vulputate quam congue vitae. Proin quis ante ac elit dapibus varius vitae id libero. Suspendisse ac maximus ipsum. Fusce id ligula urna. Praesent quam odio, dapibus non tincidunt in, tempus non eros.",
                    replies: [
                        {
                            id: 1,
                            user: {
                                name: "Olaf Tryggvason",
                                image: "https://cdn.builder.io/api/v1/image/assets/TEMP/305b2236f59dca300d60755c909d7ae0ca1c3eb09bd70877bef8ade9d3cc53c8?width=100"
                            },
                            timestamp: "30m ago",
                            content: "Praesent quam odio, dapibus non tincidunt in, tempus non eros."
                        },
                        {
                            id: 2,
                            user: {
                                name: "Olaf Tryggvason",
                                image: "https://cdn.builder.io/api/v1/image/assets/TEMP/305b2236f59dca300d60755c909d7ae0ca1c3eb09bd70877bef8ade9d3cc53c8?width=100"
                            },
                            timestamp: "30m ago",
                            content: "Praesent quam odio, dapibus non tincidunt in, tempus non eros."
                        }
                    ]
                },
                {
                    id: 2,
                    user: {
                        name: "Canute James",
                        image: "https://cdn.builder.io/api/v1/image/assets/TEMP/305b2236f59dca300d60755c909d7ae0ca1c3eb09bd70877bef8ade9d3cc53c8?width=100"
                    },
                    timestamp: "1h ago",
                    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non sapien id purus accumsan interdum vitae at felis. In interdum ex augue, sit amet vulputate quam congue vitae. Proin quis ante ac elit dapibus varius vitae id libero. Suspendisse ac maximus ipsum. Fusce id ligula urna. Praesent quam odio, dapibus non tincidunt in, tempus non eros.",
                    replies: [
                        {
                            id: 1,
                            user: {
                                name: "Olaf Tryggvason",
                                image: "https://cdn.builder.io/api/v1/image/assets/TEMP/305b2236f59dca300d60755c909d7ae0ca1c3eb09bd70877bef8ade9d3cc53c8?width=100"
                            },
                            timestamp: "30m ago",
                            content: "Praesent quam odio, dapibus non tincidunt in, tempus non eros."
                        }
                    ]
                }
            ]
        },
    ];

    useEffect(() => {
        const initialFetch = async () => {
            setIsFetching(true);
            await handleGetPost();
            setIsFetching(false);
        }

        initialFetch();
    }, [])

    const handleGetPost = async () => {
        try{
            let data = {
                token: token,
                postId: id
            }

            const response = await getPost(data);
            if(response.status === 200){
                setPost(response.data);
            }
        }
        catch(error){
            console.error("Error getting post: ", error);
        }
    }

    const handleComment = async () => {
        try{
            let data = {
                token: token,
                postId: id,
                payloads: {
                    comment: commentText
                }
            }

            const response = await addComment(data);
            if(response.status === 201){
                setPost(response.data);
            }
        }
        catch(error){
            console.error("Error gcommenting to a post: ", error);
        }
    }

    const handleReplyComment = async (commentId) => {
        try{
            let data = {
                token: token,
                commentId: commentId,
                payloads: {
                    content: replyText[commentId]
                }
            }

            const response = await replyComment(data);
            if(response.status === 201){
                setPost(response.data);
            }
        }
        catch(error){
            console.error("Error gcommenting to a post: ", error);
        }
    }

    const handleSavePost = async (id) => {
        try{
            let data = {
                token: token,
                payloads: {
                    topic_id: id,
                }
            }

            const response = await savePost(data);
            if (response.status === 200) {
                setPost((prevState) => ({
                    ...prevState,
                    is_saved: true
                }));
            }
        }
        catch(error){
            console.error("Error saving post: ", error);
        }

    };

    const handleUnsavePost = async (id) => {
        try{
            let data = {
                token: token,
                postId: id,
            }

            const response = await unsavePost(data);
            if (response.status === 200) {
                setPost((prevState) => ({
                    ...prevState,
                    is_saved: false
                }));
            }
        }
        catch(error){
            console.error("Error unsaving post: ", error);
        }

    };

    const handleToggleCommentInput = () => {
        setShowCommentInput((prev) => !prev);
    };

    const handleCommentChange = (e) => {
        setCommentText(e.target.value);
    };

    const handleCommentSubmit = async () => {
        if (commentText.trim() !== "") {
            setCommentText("");
            await handleComment();
            await handleGetPost();
            setShowCommentInput(false);
        }
    };

    const handleReplyChange = (commentId, e) => {
        setReplyText((prev) => ({
            ...prev,
            [commentId]: e.target.value,
        }));
    };

    const handleReplySubmit = async (commentId) => {
        if (replyText[commentId]?.trim() !== "") {
            await handleReplyComment(commentId);
            await handleGetPost();
            setReplyText((prev) => ({
                ...prev,
                [commentId]: "",
            }));
            setShowReplyInput((prev) => ({
                ...prev,
                [commentId]: false,
            }));
        }
    };

    const toggleReplyInput = (commentId) => {
        setShowReplyInput((prev) => ({
            ...prev,
            [commentId]: !prev[commentId],
        }));
    };

    // Votes
    const handleVoteTopic = async (vote, tData) => {
        try{
            if(vote === tData.my_vote){
                await handleDeleteTopicVote(tData.id);
                return;
            }

            let data = {
                token: token,
                topicId: tData.id,
                payloads: {
                    vote: vote
                }
            }

            const response = await voteTopic(data);
            if (response.status === 200) {
                await handleGetPost();
            }
        }
        catch(error){
            console.error("Error voting on a topic: ", error);
        }
    }
    
    const handleVoteComment = async (vote, cData) => {
        try{
            if(vote === cData.my_vote){
                await handleDeleteTopicVote(cData.id);
                return;
            }

            let data = {
                token: token,
                commentId: cData.id,
                payloads: {
                    vote: vote
                }
            }

            const response = await voteComment(data);
            if (response.status === 200) {
                await handleGetPost();
            }
        }
        catch(error){
            console.error("Error voting on a comment: ", error);
        }
    }
    
    const handleVoteReply = async (vote, rData) => {
        try{
            if(vote === rData.my_vote){
                await handleDeleteTopicVote(rData.id);
                return;
            }

            let data = {
                token: token,
                replyId: rData.id,
                payloads: {
                    vote: vote
                }
            }

            const response = await voteReply(data);
            if (response.status === 200) {
                await handleGetPost();
            }
        }
        catch(error){
            console.error("Error voting on a reply: ", error);
        }
    }

    const handleDeleteTopicVote = async (votedId) => {
        try{
            let data = {
                token: token,
                votedId: votedId,
            }

            const response = await removeVote(data);
            if (response.status === 200) {
                await handleGetPost();
            }
        }
        catch(error){
            console.error("Error deleting topic vote: ", error);
        }
    }


    const formatDateTime = (timestamp) => {
        const response = `${moment(timestamp).format('MMM D, YYYY | hh:mm A')} (${moment(timestamp).fromNow()})`;
        return response;
    }

    return (
        <div className="flex flex-col justify-center items-center px-8 pt-6 bg-white max-md:px-5">
            <div className="flex flex-col flex-1 max-w-full w-[800px] max-[1024px]:w-[590px]">
                {/* <SearchBar /> */}

                {!isFething ? (
                    <div className="flex flex-col pb-12 mt-6 w-full max-md:max-w-full">
                        <div className="flex flex-wrap mb-3 justify-between items-start w-full text-xl font-semibold text-gray-700 max-md:max-w-full">
                            <div className="flex flex-wrap flex-1 shrink gap-2 items-center basis-0 min-w-[240px] max-md:max-w-full">
                                <BackButton />
                                <button 
                                    className="self-stretch my-auto text-lg font-medium text-gray-800 flex items-center gap-2"
                                    onClick={() => navigate(`/dashboard/profile?id=${post.user.id}`)}
                                >
                                    <img 
                                        src={post.user?.profile_photo_path} 
                                        alt="avatar" 
                                        className="rounded-full w-5 h-5" 
                                    />
                                    <div>
                                        {post.user?.fullname}
                                    </div>
                                </button>
                            </div>
                            <div
                                className="cursor-pointer"
                            >
                                {post.is_saved ? (
                                    <MdBookmark size={28} className="text-yellow-500" onClick={() => handleUnsavePost(post.id)} />
                                ) : (
                                    <MdBookmarkBorder size={28} className="text-gray-500" onClick={() => handleSavePost(post.id)} />
                                )}
                            </div>
                        </div>
                        <div className="flex-1 shrink self-stretch my-auto basis-0 max-md:max-w-full">
                            {post.subject}
                        </div>
                        <div className="mt-2 text-sm leading-5 text-gray-500 max-md:max-w-full">
                            {post.content}
                        </div>
                        <div className="flex flex-wrap gap-1 items-start self-start mt-2 text-xs text-gray-50">
                            {post.tags?.map((tag, index) => (
                                <div key={index} className="gap-2 self-stretch px-2 py-1 bg-blue-700 rounded">
                                    {tag.tag}
                                </div>
                            ))}
                        </div>

                        <div className="flex gap-5 items-center pt-6 mt-2 w-full text-xs text-blue-900 max-md:max-w-full">
                            
                            <div className="flex gap-3 items-center">
                                <AiFillLike className={`text-[30px] cursor-pointer ${post.my_vote === 1 ? 'text-yellow-500':'text-gray-500'}`} onClick={() => handleVoteTopic(1, post)} />
                                <p className="text-[15px]">{post.vote_count}</p>
                                <AiFillDislike className={`text-[30px] cursor-pointer ${post.my_vote === -1 ? 'text-yellow-500':'text-gray-500'}`} onClick={() => handleVoteTopic(-1, post)} />
                            </div>

                            <button 
                            onClick={handleToggleCommentInput} className="flex gap-1 justify-center items-center self-stretch py-1.5 pr-5 pl-3.5 my-auto bg-indigo-100 rounded hover:bg-indigo-200 border-none">
                                {showCommentInput ? (
                                    <FaMinus className="object-contain shrink-0 self-stretch my-auto w-3 aspect-square" />
                                ) : (
                                    <FaPlus className="object-contain shrink-0 self-stretch my-auto w-3 aspect-square" />
                                )}
                                <div className="self-stretch my-auto">
                                    {showCommentInput ? "Cancel" : "Add Comment"}
                                </div>
                            </button>
                        </div>

                        {showCommentInput && (
                            <div className="mt-4 relative">
                                <input
                                    type="text"
                                    value={commentText}
                                    onChange={handleCommentChange}
                                    className="w-full p-2 border rounded-lg pr-12"
                                    placeholder="Write your comment here..."
                                />
                                <button
                                    onClick={handleCommentSubmit}
                                    className="absolute right-0 top-0 bottom-0 px-3 flex items-center justify-center text-blue-700 rounded-lg"
                                >
                                    <IoSend className="w-5 h-5" />
                                </button>
                            </div>
                        )}

                        <div className="flex overflow-hidden flex-col flex-1 mt-6 w-full max-md:max-w-full">
                            {post.comments?.map((comment) => (
                                <div key={comment.id} className="flex flex-col w-full max-md:max-w-full mb-6 relative comment-container">
                                    <div className="flex flex-wrap gap-2 items-center w-full max-md:max-w-full">
                                        <button 
                                            className="flex gap-1 items-center self-stretch my-auto text-sm font-medium text-gray-700"
                                            onClick={() => navigate(`/dashboard/profile?id=${comment.user.id}`)}    
                                        >
                                            <img
                                                loading="lazy"
                                                alt=""
                                                srcSet={comment.user.profile_photo_path}
                                                className="object-contain shrink-0 self-stretch my-auto w-6 rounded-full aspect-square"
                                            />
                                            <div className="gap-2 self-stretch pr-2 my-auto border-r border-solid border-r-gray-400">
                                                {comment.user.fullname}
                                            </div>
                                        </button>
                                        <div className="self-stretch my-auto text-xs text-gray-400">
                                            {formatDateTime(comment.created_at)}
                                        </div>
                                    </div>
                                    <div className="mt-2 text-sm leading-5 text-gray-500 max-md:max-w-full">
                                        {comment.comment}
                                    </div>
                                    <div className="flex gap-2 items-center w-full text-xs text-blue-900 max-md:max-w-full">
                                        <button
                                            className="flex items-center gap-3 mt-2 text-xs text-gray-600 whitespace-nowrap border-none bg-transparent cursor-pointer hover:text-gray-800"
                                        >
                                            <div className="flex gap-1">
                                                <AiFillLike className={`text-[15px] cursor-pointer ${comment.my_vote === 1 ? 'text-yellow-500':'text-gray-500'}`} onClick={() => handleVoteComment(1, comment)} />
                                                {comment.vote_count}
                                                <AiFillDislike className={`text-[15px] cursor-pointer ${comment.my_vote === -1 ? 'text-yellow-500':'text-gray-500'}`} onClick={() => handleVoteComment(-1, comment)} />
                                            </div>
                                            <div className="flex gap-1">
                                                {comment.comment_replies?.length}
                                                <FiMessageSquare className="w-4 h-4" />
                                            </div>
                                            
                                            <button 
                                                className="ml-1 underline"
                                                onClick={() => toggleReplyInput(comment.id)}
                                            >
                                                {showReplyInput[comment.id] ? "Cancel" : "Reply"}
                                            </button>
                                        </button>
                                    </div>
                                    {showReplyInput[comment.id] && (
                                        <div className="mt-4 relative ml-6 reply-container">
                                            <input
                                                type="text"
                                                value={replyText[comment.id] || ""}
                                                onChange={(e) => handleReplyChange(comment.id, e)}
                                                className="w-full p-2 border rounded-lg pr-12"
                                                placeholder="Write your reply here..."
                                            />
                                            <button
                                                onClick={() => handleReplySubmit(comment.id)}
                                                className="absolute right-0 top-0 bottom-0 px-3 flex items-center justify-center text-blue-700 rounded-lg"
                                            >
                                                <IoSend className="w-5 h-5" />
                                            </button>
                                        </div>
                                    )}
                                    <div className="flex flex-col ml-6 mt-4">
                                        {comment.comment_replies?.map((reply) => (
                                            <div key={reply.id} className="flex flex-col mb-4 reply-container">
                                                <div className="flex flex-wrap gap-2 items-center w-full">
                                                    <button 
                                                        className="flex gap-1 items-center self-stretch my-auto text-sm font-medium text-gray-700"
                                                        onClick={() => navigate(`/dashboard/profile?id=${reply.user.id}`)}     
                                                    >
                                                        <img
                                                            loading="lazy"
                                                            alt=""
                                                            srcSet={reply.user.profile_photo_path}
                                                            className="object-contain shrink-0 self-stretch my-auto w-6 rounded-full aspect-square"
                                                        />
                                                        <div className="gap-2 self-stretch pr-2 my-auto border-r border-solid border-r-gray-400">
                                                            {reply.user.fullname}
                                                        </div>
                                                    </button>
                                                    <div className="self-stretch my-auto text-xs text-gray-400">
                                                        {formatDateTime(reply.created_at)}
                                                    </div>
                                                </div>
                                                <div className="mt-2 text-sm leading-5 text-gray-500">
                                                    {reply.content}
                                                </div>
                                                <div className="mt-1 flex gap-2 items-center w-full text-xs text-blue-900 max-md:max-w-full">
                                                    <div className="flex gap-1">
                                                        <AiFillLike className={`text-[15px] cursor-pointer ${reply.my_vote === 1 ? 'text-yellow-500':'text-gray-500'}`} onClick={() => handleVoteReply(1, reply)} />
                                                        {reply.vote_count}
                                                        <AiFillDislike className={`text-[15px] cursor-pointer ${reply.my_vote === -1 ? 'text-yellow-500':'text-gray-500'}`} onClick={() => handleVoteReply(-1, reply)} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ):(
                    <div className="mt-[100px] md:mt-[300px]">
                        <Loading />
                    </div>
                )}
                
            </div>
        </div>
    );
}

export default TopViewedMain;