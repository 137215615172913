import { useEffect, useState } from "react";
import React from "react";
import BannerProfile from "../components/BannerProfile";
import { getMyReferrals } from "../../../api/apiCall.js";
import axios from 'axios';
import { FiCopy } from "react-icons/fi";
import { IoShareSocialOutline } from "react-icons/io5";

function EarningsAndReferrals() {
  const [referrals, setReferrals] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  useEffect(() => {
    console.log("Component mounted");
    fetchReferrals();
    return () => {
      console.log("Component unmounted");
    };
  }, []);

  const fetchReferrals = async () => {
    try {
      let data = {
        id: user.id,
        token: token
      }
      console.log("fetchReferrals called");
      const response = await getMyReferrals(data);
      console.log("API response:", response);
      setReferrals(response.data);
    }
    catch (error) {
      console.error("Error fetching referrals:", error.response.data);
    }
  };

  const copyReferalLink = async () => {
    try {
      await navigator.clipboard.writeText(referrals.referral_link + '&t=c');
      alert('Recovery codes copied to clipboard!');
    }
    catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const shareReferralLink = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Refer and Earn',
          text: 'Check out this referral link!',
          url: referrals.referral_link + '&t=s',
        });
        console.log('Successful share');
      } catch (error) {
        console.log('Error sharing', error);
      }
    }
    else {
      alert('Share not supported on this browser, copy the link instead');
    }
  };

  return (
    <div className="min-h-[89vh]">
      <div className="px-5">
        <BannerProfile />
        <div className="flex flex-col mt-5 w-full max-md:max-w-full">
          <div className="flex flex-col w-full max-md:max-w-full">
            <h2 className="text-sm font-medium text-gray-600">Refer and Earn</h2>
            <div className="flex flex-wrap gap-2 items-center mt-1 max-w-full w-[497px]">
              <input
                type="text"
                readOnly
                value={referrals.referral_link}
                aria-label="Referral link"
                className="gap-2 self-stretch px-4 py-2 my-auto text-sm text-gray-500 whitespace-nowrap rounded-lg border border-gray-400 border-solid min-w-[240px] w-[370px]"
              />
              <button onClick={copyReferalLink}
                aria-label="Copy referral link"
                className="flex justify-center items-center p-2 w-9 h-9 rounded-lg border border-blue-600 border-solid bg-blue-600 bg-opacity-10"
              >
                <FiCopy size={20} className="text-blue-600" />
              </button>
              <button
                onClick={shareReferralLink}
                aria-label="Share referral link"
                className="flex justify-center items-center p-2 w-9 h-9 rounded-lg border border-blue-600 border-solid bg-blue-600 bg-opacity-10"
              >
                <IoShareSocialOutline className="text-blue-600" />
              </button>
            </div>
          </div>

          {/* Referrals and Stats Section */}
          <div className="flex flex-col p-6 mt-8 w-full bg-white rounded-2xl border border border-solid max-md:px-5 max-md:max-w-full">
            <h2 className="gap-4 pb-4 w-full text-base font-bold text-gray-600 border-b border-solid max-md:max-w-full">
              My Referrals
            </h2>

            <div className="flex flex-wrap gap-4 mt-4 w-full max-md:max-w-full">
              <div
                className="flex flex-col justify-center items-center p-4 whitespace-nowrap bg-gray-200 rounded-lg border border-gray-500 border-solid"
              >
                <div className="text-sm font-medium text-gray-600">
                  Referrals
                </div>
                <div className="text-xl font-bold text-sky-600">{referrals.referrals}</div>
              </div>
              <div
                className="flex flex-col justify-center items-center p-4 whitespace-nowrap bg-gray-200 rounded-lg border border-gray-500 border-solid"
              >
                <div className="text-sm font-medium text-gray-600">
                  Badge Draw
                </div>
                <div className="text-xl font-bold text-sky-600">{referrals.badge_draw_amount}</div>
              </div>
              <div
                className="flex flex-col justify-center items-center p-4 whitespace-nowrap bg-gray-200 rounded-lg border border-gray-500 border-solid"
              >
                <div className="text-sm font-medium text-gray-600">
                  Total
                </div>
                <div className="text-xl font-bold text-sky-600">{referrals.referral_amount}</div>
              </div>
              <div className="flex flex-col justify-center items-center p-4 my-auto text-white bg-sky-600 rounded-lg">
                <div className="text-sm font-medium">Total Earned</div>
                <div className="text-2xl font-bold">{referrals.total_earned}</div>
              </div>
            </div>

            {/* Terms and Conditions */}
            <div className="flex flex-col pt-4 mt-4 w-full text-sm border-t border-solid max-md:max-w-full">
              <h3 className="font-semibold text-gray-600">Terms and Conditions</h3>
              <p className="mt-2 leading-5 text-gray-500 max-md:max-w-full">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget
                lobortis nulla, accumsan posuere dolor. Sed congue leo eros, nec
                tempor leo rutrum id. Donec lacinia lacus nec neque eleifend
                vestibulum. Donec vel porta purus, ac dignissim purus. Cras
                maximus nec tellus quis auctor. Nulla dignissim elit ut dui
                porttitor, a viverra dolor pellentesque. Curabitur vitae tincidunt
                neque. Proin et efficitur felis, at viverra turpis. Praesent
                blandit semper nisl eu dictum. Pellentesque in augue quis erat
                dapibus pellentesque a non quam. Vestibulum neque nunc, dignissim
                ornare mi vitae, ultricies feugiat elit. Maecenas orci est,
                pharetra sed dui vitae, consectetur finibus felis. Cras eget est
                tincidunt, imperdiet ligula non, pharetra mauris.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EarningsAndReferrals;
