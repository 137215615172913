import React, { useState, useEffect, useRef } from "react";
import { MdBookmarkBorder, MdBookmark } from "react-icons/md";
import { AiFillLike } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { 
    getPosts, 
    savePost,
    unsavePost,
} from "../../../../../../api/apiCall";
import PostListLoading from "./PostListLoading";

function PostList({ selectedCategory, filter, searchQuery }) {
    const token = localStorage.getItem("token");
    const [posts, setPosts] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [isSaving, setIsSaving] = useState(null);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [pages, setPages] = useState({
        current: 0,
        total: 0,
    })

    const navigate = useNavigate();

    useEffect(() => {
        if (selectedCategory && posts.length > 0) {
            if(selectedCategory.id === 1){
                setFilteredPosts(posts);
                return;
            }
            const result = posts.filter((post) => post.category_id === selectedCategory.id);
            setFilteredPosts(result);
        }
    }, [selectedCategory, posts]);

    useEffect(() => {
        console.log("Filter: ", filter);
        if (filter.data) {
            let filtered = [...filteredPosts]; // Create a copy of filteredPosts
    
            switch (filter?.data?.value) {
                case "Most Recent":
                    // Sort by the most recent created_at date
                    filtered = filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    break;
                case "Oldest":
                    // Sort by the oldest created_at date
                    filtered = filtered.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                    break;
                case "Highest Rated":
                    // Sort by the highest vote_count
                    filtered = filtered.sort((a, b) => b.vote_count - a.vote_count);
                    break;
                case "Lowest Rated":
                    // Sort by the lowest vote_count
                    filtered = filtered.sort((a, b) => a.vote_count - b.vote_count);
                    break;
                default:
                    break;
            }
    
            setFilteredPosts(filtered); // Update the filtered posts
        }
    }, [filter, filteredPosts]);

    useEffect(() => {
        console.log("Search: ", searchQuery.data?.value);
        let stock;
        let result;
        if(selectedCategory.id === 1){
            stock = posts;
        }
        else{
            stock = posts.filter((post) => post.category_id === selectedCategory.id);
        }

        if (searchQuery.data?.value !== "") {
            result = filteredPosts.filter((post) =>
                post.subject.toLowerCase().includes(searchQuery.data.value.toLowerCase())
            );
            console.log("Yes: ", result);
            setFilteredPosts(result);
        }
        else{
            console.log("No: ", stock);
            setFilteredPosts(stock);
        }
        
    }, [searchQuery]);

    useEffect(() => {
        const initialFetch = async () => {
            setIsFetching(true);
            await handleGetPosts();
            setIsFetching(false);
        };

        initialFetch();
    }, []);

    const scrollRef = useRef(null);

    const handleScroll = async () => {
        const element = scrollRef.current;
        if (element.scrollHeight - element.scrollTop <= element.clientHeight + 1) {
            if(pages.current !== pages.total){
                setIsLoadingMore(true);
                await handleLoadMore();
                setIsLoadingMore(false);
            }
        }
    };

    const handleGetPosts = async () => {
        try {
            let data = {
                token: token,
                page: 1
            };

            const response = await getPosts(data);
            if (response.status === 200) {
                setPosts(response.data.data);
                setFilteredPosts(response.data.data.filter(post => post.category_id === selectedCategory?.id));
                setPages({ current: response.data.current_page, total: response.data.last_page })
            }
        } catch (error) {
            console.error("Error getting posts: ", error);
        }
    };

    const handleLoadMore = async () => {
        try {
            let data = {
                token: token,
                page: pages.current+1
            };

            const response = await getPosts(data);
            if (response.status === 200) {
                setPosts((prevPosts) => [...prevPosts, ...response.data.data]);
                setFilteredPosts((prevPosts) => [...prevPosts, ...response.data.data.filter(post => post.category_id === selectedCategory?.id)]);
                setPages({ ...pages, current: response.data.current_page })
            }
        } catch (error) {
            console.error("Error getting posts: ", error);
        }
    }

    const handleSavePost = async (id) => {
        setIsSaving(id);
        try{
            let data = {
                token: token,
                payloads: {
                    topic_id: id,
                }
            }

            const response = await savePost(data);
            if(response.status === 200){
                setFilteredPosts((prevState) => {
                    const updatedPosts = prevState.map((post) =>
                        post.id === id ? { ...post, is_saved: true } : post
                    );
            
                    return updatedPosts;
                });
            }
        }
        catch(error){
            console.error("Error saving post: ", error);
        }
        finally{
           setIsSaving(null);
        }

    };

    const handleUnsavePost = async (id) => {
        setIsSaving(id);
        try{
            let data = {
                token: token,
                postId: id,
            }

            const response = await unsavePost(data);
            if(response.status === 200){
                setFilteredPosts((prevState) => {
                    const updatedPosts = prevState.map((post) =>
                        post.id === id ? { ...post, is_saved: false } : post
                    );
            
                    return updatedPosts;
                });
            }
        }
        catch(error){
            console.error("Error unsaving post: ", error);
        }
        finally{
            setIsSaving(null);
        }
    };

    const formatDateTime = (timestamp) => {
        return moment(timestamp).fromNow();
    };

    return (
        <div 
            ref={scrollRef}
            onScroll={handleScroll}
            className="flex overflow-auto flex-col flex-1 mt-6 w-full max-md:max-w-full max-h-[100vh] mb-5 custom-scrollbar"
        >
            
            {!isFetching ? (
                filteredPosts.map((post, index) => (
                    <div key={index} className="flex flex-col justify-center p-4 mt-2 w-full bg-white rounded-lg border border-solid max-md:max-w-full cursor-pointer hover:bg-gray-200">
                        <div className="flex justify-between items-start w-full max-md:max-w-full">
                            <button 
                                className="self-stretch my-auto text-lg font-medium text-gray-800 flex items-center gap-2"
                                onClick={() => navigate(`/dashboard/profile?id=${post.user.id}`)}
                            >
                                <img 
                                    src={post.user.profile_photo_path} 
                                    alt="avatar" 
                                    className="rounded-full w-5 h-5" 
                                />
                                <div>
                                    {post.user.fullname}
                                </div>
                                
                            </button>
                            <div className="flex gap-1.5 justify-end items-start self-stretch my-auto text-xs text-gray-500 w-[130px]">
                                <div className="self-stretch my-auto">{formatDateTime(post.created_at)}</div>
                                {isSaving === post.id ? (
                                    <div className="w-[17px] h-[17px] border-2 border-t-transparent border-[#1c3775] rounded-full animate-spin"></div>
                                ):(
                                    <div
                                        className="cursor-pointer"
                                    >
                                        {post.is_saved ? (
                                            <MdBookmark size={20} className="text-yellow-500" onClick={() => handleUnsavePost(post.id)} />
                                        ) : (
                                            <MdBookmarkBorder size={20} className="text-gray-500" onClick={() => handleSavePost(post.id)} />
                                        )}
                                    </div>
                                )}
                                
                            </div>
                        </div>
                        <div 
                            className="self-stretch my-auto text-base font-medium text-gray-800 mt-2"
                            onClick={() => navigate(`/dashboard/forum/post/${post.id}`)}
                        >
                            {post.subject}
                        </div>
                        <div className="mt-3 text-sm leading-5 text-sky-600 max-md:max-w-full">
                            {post.content.length > 200
                                ? post.content.substring(0, 200) + "..."
                                : post.content} {post.content.length > 200 && (
                                    <span className="text-sky-600" onClick={() => navigate(`/dashboard/forum/post/${post.id}`)}>see more</span>
                                )
                            }
                        </div>
                        <div className="flex gap-4 justify-between items-start pt-1 mt-1 w-full text-xs max-md:max-w-full">
                            <div className="flex flex-wrap w-auto gap-2 items-center">
                                {post.tags.map((tag, i) => (
                                    i <= 5 && (
                                        <div key={i} className="gap-2 self-stretch px-2 py-1 whitespace-nowrap bg-blue-700 rounded text-white">
                                            {tag.tag}
                                        </div>
                                    )
                                ))}
                                {post.tags.length > 5 && (
                                    <div>{post.tags.length - 5}+</div>
                                )}
                            </div>
                            <div className="flex justify-end gap-4 min-w-[110px]">
                                <button 
                                    className="flex gap-1 items-center text-gray-500 whitespace-nowrap"
                                    onClick={() => navigate(`/dashboard/forum/post/${post.id}`)}
                                >
                                    <div className="self-stretch my-auto">{post.vote_count}</div>
                                    <AiFillLike className={`text-[15px] mt-[-2px] cursor-pointer ${post.my_vote === 1 ? 'text-yellow-500':'text-gray-500'}`} />
                                </button>
                                <button 
                                    className="flex gap-1 items-center text-gray-500 whitespace-nowrap"
                                    onClick={() => navigate(`/dashboard/forum/post/${post.id}`)}
                                >
                                    <div className="self-stretch my-auto">{post.comments?.length}</div>
                                    <img
                                        loading="lazy"
                                        alt=""
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/29816c130e3d86597abf3c5630928c2d20c199a63eb7d3be097cff2a9b3f2063?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c"
                                        className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
                                    />
                                </button>
                            </div>
                            
                        </div>
                    </div>
                ))
            ) : (
                Array.from({ length: 5 }, (_, index) => (
                    <PostListLoading key={index} />
                ))
            )}

            {isLoadingMore && (
                <div className="flex flex-col items-center justify-center z-50 bg-white/75 mt-5">
                    <div className="relative flex justify-center items-center">
                    <div className="h-10 w-10 rounded-full border-t-2 border-b-2 border-gray-200"></div>
                    <div className="absolute top-0 left-0 h-10 w-10 rounded-full border-t-2 border-b-2 border-devRegBlue animate-spin"></div>
                    </div>
                    <p className="mt-4 text-sm max-sm:text-sm font-semibold text-devRegBlue animate-pulse">
                    Loading more...
                    </p>
                </div>
            )}
        </div>
    );
}

export default PostList;