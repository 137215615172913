import React from "react";
import TopBanner from "../components/TopBanner";
import Header from "./Header";
import MissionVision from "./MissionVision";
import OurVision from "./OurVision";
import DevregEssensial from "./DevregEssensial";
import BecomeRecognized from "../components/BecomeRecognized";
import MeetTheSaffs from "./MeetTheStaffs";

function AboutUsMain() {
  return (
    <div className="bg-[#F9FAFB]">
      <TopBanner imgBg={"/AboutUsHeader-BG.png"} center={false} />
      <Header />
      <MissionVision />
      <OurVision />
      <DevregEssensial />
      <BecomeRecognized />
      <MeetTheSaffs />
    </div>
  );
}

export default AboutUsMain;