import React from "react";
import { useNavigate } from "react-router-dom";

function ThreeStepRegistration() {
  const navigate = useNavigate();
  
  return (
    <>
      <div className="container flex gap-1 max-m-main max-md:px-3 px-10 justify-between max-md:flex max-md:flex-col mt-28 mx-auto max-md:justify-center">
        <div className="px-2 max-md:order-2 min-[768px]:w-[50%] max-md:px-2">
          <div className=" font-bold max-md:text-[20px] text-[32px] max-sm:mt-7">
            3-Step Registration for DevReg
          </div>
          <div className="mt-6">
            {/* step-1 */}

            <div className="flex gap-5">
              <img src="/VerticalStepper-1.svg" alt="step-1-number" />
              <div className="flex flex-col">
                <div className="text-[18px] font-bold">Register</div>
                <p className="text-black70 text-[14px] ">
                  Begin by creating your account and fill in the necessary
                  information to initiate the verification process.
                </p>
              </div>
            </div>
            {/* step-2 */}
            <div className="flex gap-5">
              <img src="/VerticalStepper-2.svg" alt="step-2-number" />
              <div className="flex flex-col">
                <div className="text-[18px] font-bold">Authenticate</div>
                <p className="text-black70 text-[14px] ">
                  Undergo quick facial recognition and submit your Passport,
                  Driver’s License, and Country ID for a secure registration
                  process.
                </p>
              </div>
            </div>
            {/* step-3 */}
            <div className="flex gap-5">
              <img src="/VerticalStepper-3.svg" alt="step-3-number" />
              <div className="flex flex-col">
                <div className="text-[18px] font-bold">Verify</div>
                <p className="text-black70 text-[14px] ">
                  Receive a unique DevReg badge number upon registration—your
                  professional identifier serving as proof of your DevReg
                  community membership.
                </p>
              </div>
            </div>
          </div>

          <button onClick={() => navigate("/register")} className="max-md:w-full max-md:ml-0 ml-12 px-10 py-3 text-center border-devRegDarkBlue bg-devRegDarkBlue hover:text-devRegDarkBlue hover:bg-transparent font-bold text-white border-[2px] rounded-full max-sm:py-2 max-sm:mt-6 max-sm:text-[16px]">
            Get Verified Now!
          </button>
        </div>
        <img
          src="/3steplogo.png"
          className="max-w-full max-sm:px-8 max-md:w-[50%] m-auto max-md:order-1 min-[768px]:w-[35%] "
          alt="logo for three step verification"
        />
      </div>
    </>
  );
}

export default ThreeStepRegistration;