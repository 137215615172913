import React, { useState, useEffect } from "react";
import BacktoLogin from "../components/BackToLogin";
import { useNavigate } from "react-router-dom";
import {
  getRecoveryCodes,
  generatetRecoveryCodes,
} from "../../../api/apiCall";

function RecoveryCodes() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const navigate = useNavigate();

  const [isCopied, setIsCopied] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleGetRecoveryCodes();
  }, [])

  const handleGetRecoveryCodes = async () => {
    setIsLoading(true);
    try {
      let data = {
        id: user.id,
        token: token,
      }

      const response = await getRecoveryCodes(data);
      if (response.status === 200) {
        console.log(response);
        setRecoveryCodes(response.data.recovery_codes);
      }
    }
    catch (error) {
      console.log("Error getting recovery codes: ", error);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleRegenerateRecoveryCodes = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {

        }
      }

      const response = await generatetRecoveryCodes(data);
      if (response.status === 200) {
        console.log(response);
        setRecoveryCodes(response.data.recovery_codes);
      }
    }
    catch (error) {
      console.log("Error getting recovery codes: ", error);
    }
  }

  const handleDownload = () => {
    const textContent = recoveryCodes.join("\n");
    const blob = new Blob([textContent], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Devreg Recovery Codes.txt";
    a.click();
    URL.revokeObjectURL(url);
  };

  const handlePrint = () => {
    const printContent = recoveryCodes.join("\n");
    const newWindow = window.open("", "_blank");
    newWindow.document.write(`<pre>${printContent}</pre>`);
    newWindow.print();
  };

  const handleCopy = () => {
    const textContent = recoveryCodes.join("\n");

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(textContent).then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      }).catch(err => {
        console.error("Failed to copy text: ", err);
      });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = textContent;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);

      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    }
  };

  return (
    <div className="flex overflow-hidden flex-col items-center px-4 pt-4 pb-12 bg-blue-900 md:px-20 md:pt-24 md:pb-44">
        <div className="flex flex-col p-4 w-full max-w-full bg-white rounded-3xl shadow-md md:p-12 md:mt-6 md:max-w-[680px]">
          <div className="flex items-center justify-between w-full mb-6">
            <div className="flex justify-start items-center gap-1 cursor-pointer text-teal-400 text-base font-medium">
              <BacktoLogin />
            </div>
            <img
              src="/logoFooter.png"
              alt="Devreg Logo"
              className="h-8"
            />
          </div>
          <hr className="w-full border-t border-gray-200" />
          <div className="flex flex-col mt-6 w-full">
            <div className="self-start text-lg font-semibold text-neutral-800 md:text-xl">
              Two-factor recovery codes
            </div>
            <div className="mt-2 text-sm text-zinc-700 text-opacity-80 md:text-base">
              Recovery codes can be used to access your account in the event you
              lose access to your device and cannot receive two-factor
              authentication codes.
            </div>
          </div>
          <div className="flex-1 shrink gap-2.5 p-4 mt-6 w-full text-sm font-medium tracking-normal leading-6 text-black bg-gray-200 rounded-xl md:text-base">
            Keep your recovery codes in a safe spot. These codes are the last
            resort for accessing your account in case you lose your password and
            second factors. If you cannot find these codes, you will lose access
            to your account.
          </div>
          <div className="flex flex-col p-4 mt-6 w-full text-sm font-medium tracking-normal leading-6 text-black bg-gray-200 rounded-xl border border-gray-400 md:flex-row md:justify-evenly md:p-6">
            {isLoading ? (
              <div className="inset-0 flex flex-col items-center justify-center z-50">
                <div className="relative flex justify-center items-center">
                  <div className="h-10 w-10 rounded-full border-t-2 border-b-2 border-gray-200"></div>
                  <div className="absolute top-0 left-0 h-10 w-10 rounded-full border-t-2 border-b-2 border-devRegBlue animate-spin"></div>
                </div>
              </div>
            ) : (
              <>
                <div className="w-full md:w-[200px] space-y-2 md:space-y-5">
                  {recoveryCodes.slice(0, 5).map((code, index) => (
                    <div key={index}>• {code}</div>
                  ))}
                </div>
                <div className="w-full md:w-[200px] space-y-2 md:space-y-5">
                  {recoveryCodes.slice(5).map((code, index) => (
                    <div key={index}>• {code}</div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="flex flex-col max-sm:flex-row max-sm:gap-1 max-sm:items-center mt-8 space-y-4 max-sm:space-y-0 max-sm:space-x-4 text-sm md:flex-row md:space-y-0 md:space-x-4 md:items-center max-md:justify-center max-[360px]:justify-center">
            <button
              onClick={handleDownload}
              className="flex gap-3 items-center px-4 py-2 border rounded-full hover:bg-gray-100 max-sm:gap-1 max-sm:justify-center"
            >
              <img
                alt="download"
                loading="lazy"
                src="/Download_light.png"
                className="object-contain w-4 aspect-square"
              />
              <div className="max-[360px]:hidden">Download</div>
            </button>
            <button
              onClick={handlePrint}
              className="flex gap-3 items-center px-4 py-2 border rounded-full hover:bg-gray-100  max-sm:gap-1 max-sm:justify-center"
            >
              <img
                alt="print"
                loading="lazy"
                src="/Print_light.png"
                className="object-contain w-4 aspect-square"
              />
              <div className="max-[360px]:hidden">Print</div>
            </button>
            <button
              onClick={handleCopy}
              className="flex gap-3 items-center px-4 py-2 border rounded-full hover:bg-gray-100  max-sm:gap-1 max-sm:justify-center"
            >
              <img
                alt="copy"
                loading="lazy"
                src="/Copy_light.png"
                className="object-contain w-4 aspect-square"
              />
              <div className="max-[360px]:hidden">{isCopied ? "Copied!" : "Copy"}</div>
            </button>
          </div>
          <div className="flex gap-6 mt-8 w-full max-md:flex-col max-md:gap-4 max-md:mt-4">
            <button
              className="px-4 py-2.5 w-1/2 max-md:w-full text-base text-white font-medium bg-blue-900 rounded-full"
              onClick={handleRegenerateRecoveryCodes}
            >
              Generate New Recovery Codes
            </button>
            <button
              className="px-4 py-2.5 w-1/2 max-md:w-full text-base font-medium text-blue-900 bg-blue-100 rounded-full"
              onClick={() => navigate("/login")}
            >
              Go to Login
            </button>
          </div>
      </div>
    </div>
  );
}

export default RecoveryCodes;
