import { useState, useEffect } from 'react';
import moment from 'moment';
import {
    getTheRegisterConversation,
    getDevregConversation,
    getAdminConversation,
    replyTheRegisterMessage,
    replyDevregMessage,
    replyAdminMessage,
} from "../../../api/apiCall";

const OpenMessageDesktop = ({ data, emit }) => {
    const token = localStorage.getItem('token');

    const [isFething, setIsFetching] = useState(false);
    const [showReplyPanel, setShowReplyPanel] = useState(false);
    const [showMessage, setShowMessage] = useState(null)
    const [replyInput, setReplyInput] = useState("");

    useEffect(() => {
        const initialFetch = async () => {
            setIsFetching(true);
            await handleGetMessageConversation();
            setIsFetching(false);
        }

        initialFetch();
        
    }, []);

    const handleGetMessageConversation = async () => {
        try{
            let formData = {
                token: token,
                conversationId: data.conversation_id
            }

            let response;
            switch(data.platform){
                case 'register':
                    response = await getTheRegisterConversation(formData);
                    break;
                case 'devreg':
                    response = await getDevregConversation(formData);
                    break;
                case 'admin':
                    response = await getAdminConversation(formData);
                    break;

                default:
                    break;
            }
            
            if(response.status === 200){
                // setShowMessage(response.data);
            }
        }
        catch(error){
            console.error("Error getting conversation: ", error);
        }
        finally{

        }
    }

    const handleDelete = () => {
        setReplyInput("");
    }

    const handleReply = async () => {
        try{
            let formData = {
                token: token,
                payloads: {
                    receiver_id: data.sender.id,
                    subject: 'Reply',
                    message_content: replyInput,
                    receiver_platform: data.platform,
                }
            }

            let response;
            switch(data.platform){
                case 'register':
                    response = await replyTheRegisterMessage(formData);
                    break;
                case 'devreg':
                    response = await replyDevregMessage(formData);
                    break;
                case 'admin':
                    response = await replyAdminMessage(formData);
                    break;

                default:
                    break;
            }

            if(response.status === 201){
                
            }
        }
        catch(error){
            console.error("Error sending reply:", error);
        }
        finally{

        }
    }

    const sendDataToParent = () => {
        emit({
            type: "setShowMessage",
            data: showMessage,
        });
    }

    const formatDateTime = (timestamp) => {
        const response = `${moment(timestamp).format('MMM D, YYYY | hh:mm A')} (${moment(timestamp).fromNow()})`;
        return response;
    }

  return (
    <div className="flex flex-col self-stretch px-8 max-md:px-5 max-md:mt-8 max-md:max-w-full">
        <div className="flex flex-wrap gap-2.5 content-center mt-6 text-2xl font-semibold tracking-tight leading-7 text-blue-900" >
            <div 
                className='flex gap-2 cursor-pointer'
                onClick={() => {
                    setShowMessage(false);
                    sendDataToParent()
                }}
            >
                <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/753002194888e74a2711616f8092dad48bcf98545518e55fcd95cd49009362ed?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="shrink-0 my-auto w-3 aspect-[0.6]"
                />
                <div className="flex-1 max-md:max-w-full">
                    {data.subject}
                </div>
            </div>
        </div>
        <div className="flex gap-2.5 mt-2 text-xs font-light tracking-tight leading-4 text-neutral-600 max-md:flex-wrap">
            <div className="flex-1 my-auto max-md:max-w-full">
                from {data.sender.fullname}
            </div>
            <div className="flex flex-1 gap-4 px-4 max-md:flex-wrap">
            <div className="flex-1 my-auto text-right">
                {formatDateTime(data.created_at)}
            </div>
            <div className="flex gap-4 px-px whitespace-nowrap">
                <div
                className="flex gap-2 cursor-pointer"
                onClick={() => setShowReplyPanel(true)}
                >
                <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/e3b56a0bae23d5fa9a254a53fe656cdfa28fdc6035188a125ee4f3d83da3ad66?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="shrink-0 aspect-[0.94] fill-neutral-600 w-[17px]"
                />
                <div className="my-auto">Reply</div>
                </div>
                <img onClick={() => handleDelete()}
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/52cda57ff4afc336a97d229352cf76c4d9fb47fe74d6e7a2101b9edaccd511e3?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="shrink-0 self-start aspect-[0.81] w-[13px]"
                />
            </div>
            </div>
        </div>
        <div className="justify-center p-8 mt-2 text-sm font-light tracking-tight leading-4 rounded-2xl border border-solid border-zinc-200 text-neutral-600 max-md:px-5 max-md:max-w-full">
            
            {data.message_content}
            
        </div>

        {showReplyPanel && (
            <>
            <div className="flex gap-2 mt-4 max-md:flex-wrap">
                <img
                alt="img"
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d1f6218a449597f3a8bc713b85905e3bdb9e14b0e6e958134a97eb98c7ddb99b?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1f6218a449597f3a8bc713b85905e3bdb9e14b0e6e958134a97eb98c7ddb99b?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1f6218a449597f3a8bc713b85905e3bdb9e14b0e6e958134a97eb98c7ddb99b?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1f6218a449597f3a8bc713b85905e3bdb9e14b0e6e958134a97eb98c7ddb99b?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1f6218a449597f3a8bc713b85905e3bdb9e14b0e6e958134a97eb98c7ddb99b?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1f6218a449597f3a8bc713b85905e3bdb9e14b0e6e958134a97eb98c7ddb99b?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1f6218a449597f3a8bc713b85905e3bdb9e14b0e6e958134a97eb98c7ddb99b?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1f6218a449597f3a8bc713b85905e3bdb9e14b0e6e958134a97eb98c7ddb99b?apiKey=3ae96302e69649f5be368f89230b0596&"
                className="shrink-0 self-start w-10 rounded-full aspect-square"
                />
                <div className="flex flex-col flex-1 max-md:max-w-full">
                <div className="flex flex-col text-xs font-light tracking-tight leading-4 rounded-2xl border border-solid border-zinc-200 text-neutral-600 max-md:max-w-full">
                    <div className="flex gap-2.5 justify-between items-start px-4 py-4 w-full max-md:flex-wrap max-md:pr-5 max-md:mb-10 max-md:max-w-full">
                    <div className="flex gap-2.5">
                        <img
                        alt="img"
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/59108dcad660c8d70d86bdf3a6018e1f86e413696d00ca82ff3efab5aac202b5?apiKey=3ae96302e69649f5be368f89230b0596&"
                        className="shrink-0 aspect-[0.94] fill-neutral-600 w-[17px]"
                        />
                        <div>to {data.from}</div>
                    </div>
                    <img
                        alt="img"
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/91ac736b9a107374c7ff8dcfcd45f86e24cdcb622659701bce3553c37245b900?apiKey=3ae96302e69649f5be368f89230b0596&"
                        className="shrink-0 aspect-[0.81] w-[13px]"
                    />
                    </div>
                    <textarea
                    onChange={(replyInput) => setReplyInput(replyInput.target.value)}
                    className="py-2 px-4 mb-4 text-sm font-light tracking-tight min-h-[300px] border-transparent outline-none "
                    ></textarea>
                </div>
                <div onClick={() => handleReply()} className="cursor-pointer justify-center self-end px-6 py-3.5 mt-2 text-lg font-semibold text-white whitespace-nowrap bg-blue-900 rounded-3xl max-md:px-5">
                    Send
                </div>
                </div>
            </div>
            </>
        )}
        </div>
  )
}

export default OpenMessageDesktop
